import { get } from 'lodash'

export function useQuestion() {
  const isRequiredQuestion = (question) => {
    const isRequiredQuestion =
      question.answers.length === 1 && get(question.answers[0]?.validation, '_exclusive.required', false)
    return isRequiredQuestion
  }

  return {
    isRequiredQuestion,
  }
}
