import * as React from 'react'
import Icon from './Icon'

const TestTube = (props) => (
  <Icon width="1em" height="1em" viewBox="0 0 511.998 511.998" {...props}>
    <path d="M478.102 342.068l-12.398-13.747-12.398 13.747c-5.66 6.276-33.896 39.01-33.896 67.173 0 25.527 20.767 46.294 46.294 46.294 25.527 0 46.294-20.767 46.294-46.294.001-28.162-28.236-60.895-33.896-67.173zm-12.396 80.078c-7.115 0-12.904-5.789-12.904-12.904 0-7.17 5.942-18.527 12.89-28.788 6.961 10.287 12.919 21.658 12.919 28.788-.001 7.115-5.79 12.904-12.905 12.904zM366.322 56.463l-28.701 17.062 6.685 11.245L38.025 266.857c-17.84 10.606-30.483 27.524-35.597 47.64-5.114 20.115-2.091 41.017 8.514 58.857 14.511 24.409 40.393 37.977 66.919 37.978 13.496 0 27.159-3.511 39.578-10.895L423.72 218.35l6.685 11.245 28.701-17.062-92.784-156.07zM100.376 371.734c-21.001 12.486-48.247 5.556-60.733-15.444-6.048-10.175-7.772-22.095-4.855-33.566 2.917-11.471 10.126-21.119 20.3-27.167l163.365-97.123 107.462 39.215-225.539 134.085zm262.586-156.109L255.5 176.41l105.869-62.94 45.289 76.178-43.696 25.977z" />
  </Icon>
)

export default TestTube
