import ReactGA from 'react-ga'
import { useHistory } from 'react-router-dom'

//todo: improve tracking. add events, return something?
const Analytics = () => {
  const history = useHistory()
  if (process.env.REACT_APP_GA) {
    ReactGA.initialize(process.env.REACT_APP_GA)
    history.listen((location) => {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname)
    })
  }
  return null
}

export default Analytics
