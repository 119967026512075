import React from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import moment from 'moment'
import { useResults } from '../../utils/hooks/useResults'
import { LoadingSpinner } from '../ui'

const Result = () => {
  let { id } = useParams()
  const { status, data, error } = useResults(id)

  if (status === 'loading') {
    return <LoadingSpinner />
  }

  if (status === 'error') {
    return <div>Error: {error.message}</div>
  }

  const result = get(data, 'data', [])

  return (
    <>
      <div className="section">
        <div className="section-title">My Results</div>
        <div className="result-breakdown">
          <h6 className="date">{moment(result?.date).format('LL')}</h6>
          <h5 className="name">Coronavirus (SARS-COV-2)</h5>
          <h4 className="result">Negative</h4>
        </div>
        <div className="doctors-message">
          <p className="message">
            {result?.patient} was evaluated recently and the patient's test result from{' '}
            {moment(result.date).format('LL')} indicate the patient currently has a negative result for COVID-19. Please
            see attached results below.
          </p>
          <h5 className="doctors-signature">Dr {result?.doctor}</h5>
        </div>
      </div>
      <div className="section bottom stretch">
        <a href={result?.url} target="_blank" rel="noopener noreferrer" className="button primary inverted fill">
          View Result PDF
        </a>
      </div>
    </>
  )
}

export default Result
