import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { get } from 'lodash'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { forgotAction } from '../../resources'
import { Field } from '../fields'

const ForgotForm = ({ history }) => {
  const dispatch = useDispatch()
  const forgot = (values) => dispatch(forgotAction(values))
  const [message, setMessage] = useState()
  const [formError, setFormError] = useState('')

  const initialValues = {
    email: '',
  }

  const resetMessagesAndErrors = () => {
    setMessage('')
    setFormError('')
  }

  const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
    resetMessagesAndErrors()

    forgot(values)
      .then((response) => {
        setMessage(response.data.message)
        resetForm()
        setSubmitting(false)
      })
      .catch((error) => {
        const message = get(error, 'response.data.error', 'Error')
        setSubmitting(false)
        if (typeof message === 'string') {
          setFormError(message);
          return;
        }
        setErrors(message)
      })
  }

  const ForgotSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('required'),
  })

  return (
    <Formik initialValues={initialValues} validationSchema={ForgotSchema} onSubmit={onSubmit}>
      {(props) => (
        <form className="Form section tight columns" onSubmit={props.handleSubmit}>
          <div className="section-title">Forgot</div>
          {message && <div className="success-message">{message}</div>}
          {formError && <div className="error-message">{formError}</div>}
          <div className="inner-section stretch form-fields">
            <Field name="email" placeholder="Email" />
            <button
              className={classNames('button primary', { inactive: !(props.isValid && props.dirty) })}
              type="submit"
            >
              {!props.isSubmitting ? 'Submit' : 'Submiting...'}
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default withRouter(ForgotForm)
