import React, { useState, useEffect } from 'react'
import { connect as formikConnect } from 'formik'
import { keys, map, find, get } from 'lodash'
import Select from 'react-select'
import { Field } from '../fields'
import { ALGO_OPTIONS } from '../../utils/constants/intake-questions'

const CUSTOM_OPTION = {
  value: 'CUSTOM',
  label: 'Custom',
}

const PlanAndAssessment = ({ algoKey, canEdit, formik: { setFieldValue } }) => {
  const [selectedPlan, setSelectedPlan] = useState(algoKey || CUSTOM_OPTION.value)

  const plan_options = map(keys(ALGO_OPTIONS), (optionKey) => {
    return {
      value: optionKey,
      label: ALGO_OPTIONS[optionKey].label,
    }
  })

  const options = [...plan_options, CUSTOM_OPTION]

  useEffect(() => {
    const algo = get(ALGO_OPTIONS, selectedPlan, { assessment: '', plan: '' })
    setFieldValue('assessment', algo.assessment)
    setFieldValue('plan', algo.plan)
    setFieldValue('order_test', algo.order_test)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan])

  return (
    <>
      {canEdit && (
        <Select
          name="suggested"
          classNamePrefix="select"
          className="Field Select"
          placeholder="Plan"
          options={options}
          value={find(options, (option) => option.value === selectedPlan)}
          onChange={(option) => {
            setSelectedPlan(option.value)
          }}
        />
      )}
      <Field
        readOnly={!canEdit}
        name="assessment"
        label="Assessment"
        placeholder="Enter patient's assessment"
        component="textarea"
        type="file" accept="audio/*" capture
        withRecorder={canEdit}
      />
      <Field
        readOnly={!canEdit}
        name="plan"
        label="Plan"
        placeholder="Enter patient's plan"
        component="textarea"
        type="file" accept="audio/*" capture
        withRecorder={canEdit}
      />
    </>
  )
}

export default formikConnect(PlanAndAssessment)
