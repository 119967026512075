import { set, isEmpty } from 'lodash'
import * as Yup from 'yup'

// TODO: Clean up and allow mutltiple
// TODO: Refactor me, recursion
export function useForm(questions) {
  const validationSchema = questions.reduce((questions, question) => {
    const validationSchema = question.answers.reduce((validationObj, field) => {
      if (field.validation && field.allowMultiple !== true) {
        set(validationObj, field.name, field.validation, '')
      }

      if (field.fields && field.allowMultiple !== true) {
        const fieldsValidation = field.fields.reduce((fieldsObj, fieldsField) => {
          if (fieldsField.validation) {
            set(fieldsObj, fieldsField.name, fieldsField.validation, '')
          }
          return fieldsObj
        }, {})

        set(validationObj, field.name, Yup.object().shape({ ...fieldsValidation }))
      }

      if (field.fieldGroups && field.allowMultiple !== true) {
        const fieldGroupValidation = field.fieldGroups.reduce((fieldGroupsObj, fieldGroup) => {
          const fieldsValidationTwo = fieldGroup.fields.reduce((fieldsObj, fieldsField) => {
            if (fieldsField.validation) {
              set(fieldsObj, fieldsField.name, fieldsField.validation, '')
            }
            return fieldsObj
          }, {})

          if (!isEmpty(fieldsValidationTwo)) {
            set(fieldGroupsObj, `${fieldGroup.name}`, Yup.object().shape({ ...fieldsValidationTwo }), '')
          }

          return fieldGroupsObj
        }, {})
        set(validationObj, field.name, Yup.object().shape({ ...fieldGroupValidation }))
      }

      return validationObj
    }, {})

    return {
      ...questions,
      ...validationSchema,
    }
  }, {})

  return {
    validationSchema: Yup.object().shape({ ...validationSchema }),
  }
}
