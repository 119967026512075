import React, { useState } from 'react'
import { connect as formikConnect } from 'formik'
import { get, isEmpty } from 'lodash'
import Modal from 'react-modal'
import classNames from 'classnames'
import { Tick } from '../icons'

Modal.setAppElement('#root')

const FieldsModal = ({ children, label, edit, name, formik: { errors, touched } }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const error = get(errors, name)
  const touch = get(touched, name)
  const isRequired = !isEmpty(error) && !isEmpty(touch)
  const isValid = isEmpty(error) && !isEmpty(touch)

  // console.log('##########', label, name, isEmpty(error), !isEmpty(touch), get(touched, name))

  return (
    <div className="FieldsModal">
      <div className="field-label-wrapper">
        <div className="field-label">
          {label}
          <span className="required">*</span>
        </div>
        {isRequired && <div className="error">Required</div>}
      </div>
      <div className="button-wrapper">
        <div className={classNames('button short center', { inverted: !isValid })} onClick={() => setIsModalOpen(true)}>
          <div>Complete this form</div>
          {isValid && (
            <div className="icon-container">
              <Tick />
            </div>
          )}
        </div>
      </div>
      <Modal className="Modal" isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <div className="header">{label}</div>
        <div className="body">{children}</div>
        <div className="footer">
          <div className="button short" onClick={() => setIsModalOpen(false)}>
            Done
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default formikConnect(FieldsModal)
