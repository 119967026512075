import React from 'react'
import classNames from 'classnames'
import { FieldsModal } from './'

const FieldsWrapper = ({ condition, children, className, ...rest }) => {
  return (
    <div className={classNames('FieldsWrapper', className)}>
      {condition ? <FieldsModal {...rest}>{children}</FieldsModal> : children}
    </div>
  )
}

export default FieldsWrapper
