import React, { useEffect, useState } from 'react'
import { get, keys, every } from 'lodash'
import classNames from 'classnames'

const CheckAll = ({
  label,
  name,
  fields,
  readOnly,
  formik: { values, setFieldValue, setFieldTouched, validateForm, setValues },
}) => {
  const [checkAll, setCheckAll] = useState('')

  const onChange = (evt) => {
    const currentValue = evt.target.value
    fields.forEach((field) => {
      if (field.component === 'radio' || field.component === 'checkbox') {
        setFieldValue(`${name}.${field.name}`, currentValue)
        setFieldTouched(`${name}.${field.name}`, true)
      }
    })
  }

  useEffect(() => {
    const currentValues = get(values, name)
    if (every(keys(currentValues), (key) => currentValues[key] === 'true')) {
      setCheckAll('true')
    } else if (every(keys(currentValues), (key) => currentValues[key] === 'false')) {
      setCheckAll('false')
    } else {
      setCheckAll('')
    }
    validateForm()
  }, [name, values, validateForm])

  return (
    <div className={classNames('CheckAll', { 'read-only': readOnly })}>
      <h6 className="primary bold">{label}</h6>
      <section className="Choices">
        <label key={'true'} className="ChoiceInput">
          <div className="label-container">Yes to all</div>
          <input
            type="radio"
            name={name}
            value="true"
            onChange={(evt) => onChange(evt)}
            checked={checkAll === 'true'}
          />
          <div className="pseudo-input-container" />
        </label>
        <label key={'false'} className="ChoiceInput">
          <div className="label-container">No to all</div>
          <input
            type="radio"
            name={name}
            value="false"
            onChange={(evt) => onChange(evt)}
            checked={checkAll === 'false'}
          />
          <div className="pseudo-input-container" />
        </label>
      </section>
    </div>
  )
}

export default CheckAll
