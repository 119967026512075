import React from 'react'
import { Back } from '../../../components/general'
import ResultsList from './ResultsList'

const ReviewResults = () => {
  return (
    <div className="View ReviewResults">
      <div className="section header">
        <Back to="/" />
      </div>
      <div className="section">
        <div className="section-title">Unassigned Results</div>
        <ResultsList />
      </div>
    </div>
  )
}

export default ReviewResults
