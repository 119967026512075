import React from 'react'
import Icon from './Icon'

const Visibility = props => (
  <Icon viewBox="2 2 19 19" className="fill" {...props}>
    {/*<path d="M0 0h24v24H0z" fill="none"/>*/}
    <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z" />
  </Icon>
)

export default Visibility
