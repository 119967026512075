import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

const FallbackCard = ({ message, to, toText, icon: Icon, className }) => {
  return (
    <Link className={classNames('FallbackCard', className)} to={to}>
      {Icon && <Icon />}
      <div className="message">{message}</div>
      <div className="link">{toText}</div>
    </Link>
  )
}

export default FallbackCard
