import React from 'react'
import { Logo as LogoIcon, LogoSymbol } from '../icons/'

const Logo = () => {
  return (
    <div className="Logo">
      <LogoSymbol className="symbol" />
      <LogoIcon className="text" />
    </div>
  )
}

export default Logo
