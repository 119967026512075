import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card } from '../ui'

const AppointmentCard = ({ appointment }) => {
  const { role } = useSelector((state) => state.auth.me)
  const appointmentRole = role === 'PATIENT' ? 'PROVIDER' : 'PATIENT'
  const withUser = appointment?.users.find((user) => user.role === appointmentRole)
  const withName = role === 'PATIENT' ? `Dr. ${withUser.lastName}` : `${withUser.firstName} ${withUser.lastName}`

  const when = moment(appointment.when)
  const dayOfMonth = when.format('DD')
  const month = when.format('MMM')
  const time = when.format('h:mm a')

  //TODO: Move to Constants
  const isRefundPending = appointment?.refundStatus === 'pending'
  const isRefunded = appointment?.refundStatus === 'completed'
  const isRescheduleRequired = appointment && !appointment.chronoId

  const isRefund = isRefundPending || isRefunded
  const isComplete = moment(appointment?.when).diff(moment(), 'minutes') <= -15

  const isValid = role === 'PATIENT' ? !isRefund && !isComplete : !isRefund

  let badge
  if (isRefundPending) {
    badge = <div className="badge neutral">Refund Pending</div>
  } else if (isRefunded) {
    badge = <div className="badge neutral">Refunded</div>
  } else if (isComplete) {
    badge = <div className="badge success">Completed</div>
  } else if (isRescheduleRequired) {
    badge = <div className="badge danger">Reschedule Required</div>
  } else {
    badge = null
  }

  const link = !isComplete ? 'pre' : 'post'

  return (
    <Card className="appointment-card" to={isRescheduleRequired && role === 'PATIENT' ? `reschedule?appointmentId=${appointment.id}&reschedule=true` : isValid && `/appointment/${appointment.id}/${link}`}>
      <div className="date">
        <div className="day-of-month">{dayOfMonth}</div>
        <div className="month">{month}</div>
      </div>
      <div className="details">
        <div className="name">{withName}</div>
        <div className="time-and-badge">
          <div className="time">{time}</div>
          {badge && badge}
        </div>
      </div>
    </Card>
  )
}

export default AppointmentCard
