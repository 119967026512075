import React from 'react'
import { Formik } from 'formik'
import { WizardStep } from './'
import { withWizard } from './WizardProvider'
import { useForm } from '../../utils/hooks/useForm'

const Wizard = ({ initialValues, validation, onSubmit, steps, step, ...etc }) => {
  const { validationSchema } = useForm(steps[step])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      component={(props) => <WizardStep key={step} questions={steps[step]} step={step} {...etc} {...props} />}
      onSubmit={onSubmit}
      enableReinitialize={true}
    />
  )
}

export default withWizard(Wizard)
