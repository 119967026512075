import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import { IntakeFields } from '../intake/'
import { ProgressBar } from './'

const WizardStep = ({
  questions,
  handleSubmit,
  validateForm,
  next,
  previous,
  isFirstStep,
  isLastStep,
  step,
  stepsLength,
  values,
  setFieldTouched,
  isValid,
}) => {
  useEffect(() => {
    validateForm().then((validation) => isEmpty(validation))
  }, [validateForm])

  const validateAndNext = () => {
    validateForm().then((validation) => isEmpty(validation) && next())
    questions.forEach((question) =>
      question.answers.forEach((field) =>
        field.fieldGroups
          ? field.fieldGroups.forEach((fieldGroup) =>
              fieldGroup.fields.forEach((innerField) => setFieldTouched(`${field.name}.${innerField.name}`))
            )
          : field.fields
          ? field.fields.forEach((innerField) => setFieldTouched(`${field.name}.${innerField.name}`))
          : setFieldTouched(field.name)
      )
    )
  }

  const previousButton = !isFirstStep && (
    <div type="button" className={'link'} onClick={previous}>
      Back
    </div>
  )

  const continueButton = isLastStep ? (
    <button className={classNames('button primary', { inactive: !isValid })} type="submit">
      Save
    </button>
  ) : (
    <>
      <button type="button" className={classNames('button primary', { inactive: !isValid })} onClick={validateAndNext}>
        Next
      </button>
    </>
  )

  return (
    <form className="Form Wizard section columns stretch" onSubmit={handleSubmit}>
      <div className="form-section header">
        <ProgressBar progress={(100 / stepsLength) * (step + 1)} />
      </div>
      <div className="form-section form-fields spacious stretch">
        <IntakeFields questions={questions} values={values} />
      </div>
      <div className="form-section fixed bottom footer">
        {continueButton}
        {previousButton}
      </div>
    </form>
  )
}

export default WizardStep
