import React from 'react'
import { AppointmentList } from '../../../components/appointments'
import { Back } from '../../../components/general'

const Appointments = () => {
  return (
    <div className="View Appointments">
      <div className="section header">
        <Back to="/" />
      </div>
      <div className="section">
        <div className="section-title">My Appointments</div>
        <AppointmentList />
      </div>
    </div>
  )
}

export default Appointments
