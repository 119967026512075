import { parse, getParser } from 'bowser'

export const BROWSER_MAP = {
  amazon_silk: 'Amazon Silk',
  android: 'Android Browser',
  bada: 'Bada',
  blackberry: 'BlackBerry',
  chrome: 'Chrome',
  chromium: 'Chromium',
  electron: 'Electron',
  epiphany: 'Epiphany',
  firefox: 'Firefox',
  focus: 'Focus',
  generic: 'Generic',
  googlebot: 'Googlebot',
  google_search: 'Google Search',
  ie: 'Internet Explorer',
  k_meleon: 'K-Meleon',
  maxthon: 'Maxthon',
  edge: 'Microsoft Edge',
  mz: 'MZ Browser',
  naver: 'NAVER Whale Browser',
  opera: 'Opera',
  opera_coast: 'Opera Coast',
  phantomjs: 'PhantomJS',
  puffin: 'Puffin',
  qupzilla: 'QupZilla',
  qq: 'QQ Browser',
  qqlite: 'QQ Browser Lite',
  safari: 'Safari',
  sailfish: 'Sailfish',
  samsung_internet: 'Samsung Internet for Android',
  seamonkey: 'SeaMonkey',
  sleipnir: 'Sleipnir',
  swing: 'Swing',
  tizen: 'Tizen',
  uc: 'UC Browser',
  vivaldi: 'Vivaldi',
  webos: 'WebOS Browser',
  wechat: 'WeChat',
  yandex: 'Yandex Browser',
};

export const PLATFORMS_MAP = {
  tablet: 'tablet',
  mobile: 'mobile',
  desktop: 'desktop',
  tv: 'tv',
};

export const OS_MAP = {
  WindowsPhone: 'Windows Phone',
  Windows: 'Windows',
  MacOS: 'macOS',
  iOS: 'iOS',
  Android: 'Android',
  WebOS: 'WebOS',
  BlackBerry: 'BlackBerry',
  Bada: 'Bada',
  Tizen: 'Tizen',
  Linux: 'Linux',
  ChromeOS: 'Chrome OS',
  PlayStation4: 'PlayStation 4',
  Roku: 'Roku',
};

export const ENGINE_MAP = {
  EdgeHTML: 'EdgeHTML',
  Blink: 'Blink',
  Trident: 'Trident',
  Presto: 'Presto',
  Gecko: 'Gecko',
  WebKit: 'WebKit',
};

const getBrowserData = () => parse(window.navigator.userAgent)

export const isMobile = () => getBrowserData().platform.type === PLATFORMS_MAP.mobile

export const getBrowser = () => getBrowserData().browser

export const getOs = () => getBrowserData().os

export const getBrowserName = () => getBrowser().name

export const getBrowserVersion = () => getBrowser().version

export const getOsName = () => getOs().name

export const getOsVersion = () => getOs().version

export const isIos = () => getOs().name === OS_MAP.iOS

export const isBrowserChrome = () => getBrowserName() === BROWSER_MAP.chrome

export const isBrowserIE = () => getBrowserName() === BROWSER_MAP.ie

export const isBrowserEdge = () => getBrowserName() === BROWSER_MAP.edge

export const isBrowserSafari = () => getBrowserName() === BROWSER_MAP.safari

export const isBrowserFirefox = () => getBrowserName() === BROWSER_MAP.firefox


const minRequiredBrowsers = getParser(window.navigator.userAgent).satisfies({
  macos: {
    safari: '>12.1',
  },

  mobile: {
    safari: '>12.2',
  },

  chrome: '>55',
  firefox: '>40',
  'Microsoft Edge': '>44',
})

export const isUnSupportedBrowser = !minRequiredBrowsers || (isMobile() && !isBrowserSafari()) || isBrowserIE();