import React, { useState } from 'react'
import Modal from 'react-modal'
import { IntakeFields } from '../intake'

Modal.setAppElement('#root')

const DoctorNotesSection = ({ title, questions, canEdit, ...rest }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <div className="DoctorNotesSection">
      <div className="section-title">
        <div className="title">{questions[0].title}</div>
        {canEdit && (
          <div className="link small" onClick={() => setIsModalOpen(true)}>
            Edit
          </div>
        )}
      </div>
      {canEdit && (
        <Modal
          className="Modal DoctorNotesModal sticky-footer"
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
        >
          <div className="header">{questions[0].title}</div>
          <div className="body">
            <IntakeFields allowModals={false} questions={questions} isDoctor={true} />
          </div>
          <div className="footer sticky">
            <div className="button short" onClick={() => setIsModalOpen(false)}>
              Done
            </div>
          </div>
        </Modal>
      )}
      <IntakeFields questions={questions} readOnly={true} allowModals={false} isDoctor={true} />
    </div>
  )
}

export default DoctorNotesSection

DoctorNotesSection.defaultProps = {
  canEdit: true,
}
