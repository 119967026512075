import { useQuery } from 'react-query'
import { protectedRequest } from '../requests/api'

const getResults = async _ => {
  return protectedRequest('GET', 'results/unassigned')
}

export default function useResultsReview() {
  return useQuery(['results'], getResults)
}
