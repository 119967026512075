import React, { useEffect, useState } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { NavBar } from '../../../../components/ui'
import { getAppointmentById, addAppointmentPatientData } from '../../../../utils/hooks/useAppointment'
import { validateStripeCheckout } from '../../../../utils/hooks/useStripe'

const checkAppointmentPaymentStatus = (appointmentId, resolve, reject) => {
  setTimeout(() => {
    getAppointmentById('', appointmentId)
      .then((response) => {
        if (response.data.paymentStatus === 'paid') {
          console.log('Resolve')
          return resolve()
        }
        return checkAppointmentPaymentStatus(appointmentId, resolve, reject)
      })
      .catch(reject)
  }, 1000)
}

const createCheckAppointmentPaymentStatus = (appointmentId) => {
  return new Promise((resolve, reject) => {
    checkAppointmentPaymentStatus(appointmentId, resolve, reject)
  })
}

const handleSuccessFlow = (location, history, appointmentId, state, setMessage) => () => {
  setMessage('Loading...')
  validateStripeCheckout(appointmentId, state)
    .then((response) => {
      if (response.paymentStatus === 'paid') {
        return Promise.resolve()
      }
      setMessage('Loading payment status...')
      return createCheckAppointmentPaymentStatus(appointmentId)
    })
    .then(() => {
      setMessage('Submitting Patient Data...')
      const notes = JSON.parse(localStorage.getItem('intake'))
      return addAppointmentPatientData(appointmentId, notes)
    })
    .then((response) =>
      setTimeout(() => {
        setMessage('Redirecting To Dashboard...')
        history.push('/dashboard')
      }, 2000)
    )
    .catch((error) => {
      console.log(error)
      setMessage(error?.response?.data?.error || 'Error Message')
    })
}

const StripeCheckoutSuccess = ({ location, history }) => {
  const [appointmentId] = useQueryParam('appointmentId', StringParam)
  const [state] = useQueryParam('state', StringParam)
  const [message, setMessage] = useState()
  useEffect(handleSuccessFlow(location, history, appointmentId, state, setMessage), [])

  return (
    <div className="View">
      <div className="section"></div>
      <NavBar />

      <div className="section">
        <div className="section-title">Stripe Checkout Success</div>

        <p>{message}</p>
      </div>
    </div>
  )
}

export default StripeCheckoutSuccess
