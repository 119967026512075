import React from 'react'
import { withAppointment } from './AppointmentProvider'
import { AppointmentHeader, DoctorNotes } from '../../../../components/appointments'

const PostAppointment = ({ appointmentId, patient, patientData }) => {
  return (
    <div className="PostAppointment View">
      <AppointmentHeader patient={patient} back={false} />
      {/* Pull in NOTES*/}
      <DoctorNotes canEdit patientData={patientData} />
    </div>
  )
}

export default withAppointment(PostAppointment)
