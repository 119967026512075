import React from 'react'
import { Back } from '../../../components/general'
import { ResultsList } from '../../../components/results'

const Results = () => {
  return (
    <div className="View Results">
      <div className="section header">
        <Back to="/" />
      </div>
      <div className="section">
        <div className="section-title">My Results</div>
        <ResultsList />
      </div>
    </div>
  )
}

export default Results
