import React, { useEffect } from 'react'
import { isUndefined } from 'lodash'
import { useQueryParam, NumberParam } from 'use-query-params'

export const withWizard = (Wrapped) => {
  const WithWizard = ({ ...props }) => {
    const [step, setStep] = useQueryParam('step', NumberParam)

    useEffect(() => {
      setStep(0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const previous = () => {
      setStep(step - 1)
    }

    const next = () => {
      setStep(step + 1, 'push')
    }

    const stepsLength = props.steps && props.steps.length

    const isFirstStep = step === 0
    const isLastStep = step === stepsLength - 1

    return (
      !isUndefined(step) && (
        <Wrapped
          step={step}
          previous={previous}
          next={isLastStep ? null : next}
          stepsLength={stepsLength}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          {...props}
        />
      )
    )
  }

  return WithWizard
}
