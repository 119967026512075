import React from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import { useAppointment } from '../../../../utils/hooks/useAppointment'
import { LoadingSpinner } from '../../../../components/ui'
import { Back } from '../../../../components/general'

export const withAppointment = (Wrapped) => {
  const WithAppointment = ({ ...props }) => {
    let { id } = useParams()
    const { status, data, error } = useAppointment(id)

    if (status === 'loading') {
      return <LoadingSpinner />
    }

    if (status === 'error') {
      return (
        <div className="View Appointments">
          <div className="section header">
            <Back to="/" />
          </div>
          <div className="section">
            <div className="section-title">Error occurred while fetching appointment</div>
            <div>Error: {error.message}</div>
          </div>
        </div>
      )
    }

    const currentAppointment = get(data, 'data')
    const patient = currentAppointment.users.find((user) => user.role === 'PATIENT')
    const { id: appointmentId, when, patientData } = currentAppointment

    return <Wrapped patient={patient} patientData={patientData} when={when} appointmentId={appointmentId} {...props} />
  }

  return WithAppointment
}
