import React from 'react'
const LeftRoom = ({ other }) => {
  console.log('left room')
  return (
    <div className="WaitingRoom View section">
      <div className="section-title">Your {other} left.</div>
    </div>
  )
}
export default LeftRoom
