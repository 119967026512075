import React from 'react'
import { FieldArray, connect as FormikConnect } from 'formik'
import classNames from 'classnames'
import { Field, CheckAll } from './'

const StandardFields = ({ fieldsName, fields, ...rest }) =>
  fields.map((field, fieldIndex) => {
    return <Field key={field.name} {...field} name={`${fieldsName}.${field.name}`} {...rest} />
  })

const FieldArrayFields = ({ fieldsName, fields, formik: { values }, ...rest }) => (
  <FieldArray
    name={fieldsName}
    render={(arrayHelpers) => (
      <>
        {values[fieldsName] &&
          values[fieldsName].length > 0 &&
          values[fieldsName].map((value, index) => (
            <div key={index} className="field-array-input-group">
              {index > 0 && (
                <div className="link danger remove-item x-small" onClick={() => arrayHelpers.remove(index)}>
                  Remove
                </div>
              )}
              <StandardFields fields={fields} fieldsName={`${fieldsName}[${index}]`} />
            </div>
          ))}
        <div className="add-item link secondary small" onClick={() => arrayHelpers.push('')}>
          + Add Item
        </div>
      </>
    )}
  />
)

const Fields = ({ fields, name, label, allowMultiple, allowCheckAll, options, ...rest }) => {
  const group = fields.length > 1
  return (
    <div className="Fields">
      {allowCheckAll && <CheckAll fields={fields} label={label} name={name} {...rest} />}
      {label && <div className={classNames('field-label')}>{label}</div>}
      <div className={classNames('field-inputs', { group: group })}>
        {allowMultiple ? (
          <FieldArrayFields fields={fields} {...rest} fieldsName={name} />
        ) : (
          <StandardFields key={name} fields={fields} fieldsName={`${name}`} {...rest} />
        )}
      </div>
    </div>
  )
}

export default FormikConnect(Fields)
