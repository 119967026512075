import React from 'react'
import AddressIntakeForm from '../../../components/forms/AddressIntakeForm'

const AddressIntake = () => {
  return (
    <div className="AddressIntake View">
      <AddressIntakeForm />
    </div>
  )
}

export default AddressIntake
