import React from 'react'
import Icon from './Icon'

const SoreThroat = (props) => (
  <Icon viewBox="0 0 32 32" className="fill-none" {...props}>
    <path d="M28 23h-6a4 4 0 0 0-4 4v5" className="stroke tertiary" strokeWidth={2} />
    <path
      d="M22 32v-2.707c0-.69.56-1.25 1.25-1.25a3.75 3.75 0 0 0 3.75-3.75V20a1 1 0 0 1 1-1h3v-2.83C31 7.792 24.284 1 16 1 7.716 1 1 7.792 1 16.17c0 3.485 1.231 6.614 3.224 9.129C5.757 27.233 7 29.486 7 31.955V32"
      className="stroke primary"
      strokeWidth={2}
    />
  </Icon>
)

export default SoreThroat
