import React from 'react'
import { OrderTestForm } from '../../../components/forms'

const OrderTest = () => {
  return (
    <div className="OrderTest View">
      <OrderTestForm />
    </div>
  )
}

export default OrderTest
