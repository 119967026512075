import React from 'react'
import { get, isEmpty } from 'lodash'
import { ResultCard } from '.'
import { FallbackCard, LoadingSpinner } from '../ui'
import { useResults } from '../../utils/hooks/useResults'

const ResultsList = () => {
  const { status, data, error } = useResults()

  if (status === 'loading') {
    return <LoadingSpinner />
  }

  if (status === 'error') {
    return <div>Error: {error.message}</div>
  }

  const results = get(data, 'data', [])
  const fallBackMessage = 'You do not have any results yet...'
  const to = '/'
  const toText = 'Back to dashboard'

  return (
    <div className="Appointments">
      {!isEmpty(results) ? (
        results.map((result) => <ResultCard key={result.id} result={result} />)
      ) : (
        <FallbackCard message={fallBackMessage} to={to} toText={toText} />
      )}
    </div>
  )
}

export default ResultsList
