import React from 'react'
import { get, isEmpty } from 'lodash'
import useReviewResults from '../../../utils/hooks/useReviewResults'
import useTestOrders from '../../../utils/hooks/useTestOrders'
import ResultCard from './ResultCard'
import { LoadingSpinner, FallbackCard } from '../../../components/ui'
import { queryCache } from 'react-query'
import { assignResult } from '../../../utils/hooks/useReviewResult'

const ResultsList = () => {
  const { status: reviewResultsStatus, data, error } = useReviewResults();
  const { status: testOrdersStatus, data: testOrdersData, } = useTestOrders();

  const results = get(data, 'data')
  const testOrders = get(testOrdersData, 'data')

  const assignTestResult = async ({ id, testOrderId }, cb) => {
    await assignResult('', id, testOrderId)
    cb()
    queryCache.refetchQueries('results')
  }

  if (reviewResultsStatus === 'loading' || testOrdersStatus === 'loading') {
    return <LoadingSpinner />
  }

  if (reviewResultsStatus === 'error') {
    return <div>Error: {error.message}</div>
  }

  return (
    <div className="ResultList">
      {!isEmpty(results) ? (
        results.map((result) => <ResultCard assignTestResult={assignTestResult} testOrders={testOrders} key={result.id} result={result} />)
      ) : (
          <FallbackCard message="There are no results for review yet..." to="/" toText="Back to dashboard" />
        )}
    </div>
  )
}

export default ResultsList

