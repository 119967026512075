import React from 'react'
import Icon from './Icon'

const Lungs = (props) => (
  <Icon viewBox="0 0 32 32" className="stroke fill-none" {...props}>
    <path
      d="M2.86 15.42L4.94 9.18a4.65 4.65 0 0 1 9.06 1.47v15.23C14 27.05 13.05 28 11.879 28c-.563 0-1.102.224-1.5.621l-.93.93A4.95 4.95 0 0 1 5.95 31h-.83A4.119 4.119 0 0 1 1 26.881c0-3.895.628-7.765 1.86-11.46zM29.14 15.42L27.06 9.18a4.65 4.65 0 0 0-9.06 1.47v15.23C18 27.05 18.95 28 20.121 28c.563 0 1.103.224 1.5.621l.93.93A4.95 4.95 0 0 0 26.05 31h.83A4.118 4.118 0 0 0 31 26.881c0-3.895-.628-7.765-1.86-11.46z"
      className="stroke tertiary"
      strokeWidth={2}
    />
    <path d="M16 0v11a5 5 0 0 1-5 5h-1M16 0v11a5 5 0 0 0 5 5h1" className="stroke primary" strokeWidth={2} />
    <path d="M27 1l-1 4M5 1l1 4M31 5l-3 2M1 5l3 2" className="stroke secondary" strokeWidth={2} />
  </Icon>
)

export default Lungs
