import React, { useState } from 'react'
import Select from 'react-select'
import { queryCache } from 'react-query'
import { get, isEmpty } from 'lodash'
import classNames from 'classnames'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { LoadingSpinner, FallbackCard } from '../../../components/ui'
import useTestOrders, { updateTestOrderTrackingNumber } from '../../../utils/hooks/useTestOrders'
import { Field } from '../../../components/fields'

const TestOrdersList = () => {
  const { status, data, error } = useTestOrders()
  const [selectedOption, setSelectedOption] = useState()
  const [message, setMessage] = useState('')
  const testOrders = get(data, 'data')

  if (status === 'loading') {
    return <LoadingSpinner />
  }

  if (status === 'error') {
    return <div>Error: {error.message}</div>
  }

  const onSelectOption = (option) => {
    setMessage('')
    setSelectedOption(option)
  }

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const testOrderId = selectedOption.value
    try {
      await updateTestOrderTrackingNumber(testOrderId, values)
      queryCache.refetchQueries('testOrders')
      setSubmitting(false)
      resetForm()
      setSelectedOption(null)
      setMessage('Successfully updated the tracking number. The user has been notified')
    } catch (err) {
      setSubmitting(false)
      setMessage('')
      console.log(err)
    }
  }

  const initialValues = {
    trackingNumber: '',
  }

  const validationSchema = Yup.object().shape({
    trackingNumber: Yup.string().required('required'),
  })

  return (
    <div className="ResultList">
      {!isEmpty(testOrders) ? (
        <>
          <p>Select a test order then enter the tracking number. The user will be notified via email</p>
          <Select
            className="select-field"
            classNamePrefix="select"
            value={selectedOption}
            options={testOrders}
            onChange={onSelectOption}
            isClearable
            isSearchable
          />
          {message && <div className="success">{message}</div>}

          {selectedOption && (
            <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
              {({ isSubmitting, isValid }) => (
                <Form className="Form column stretch">
                  <Field
                    name="trackingNumber"
                    label="Tracking Number"
                    placeholder="Enter the tracking number for the test order"
                  />
                  <div className="section fixed bottom">
                    <button
                      className={classNames('button primary', { inactive: !isValid })}
                      disabled={!isValid && isSubmitting}
                      type="submit"
                    >
                      {isSubmitting ? 'Sending...' : 'Send Tracking Number'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </>
      ) : (
        <FallbackCard message="There are no test orders for shipping yet..." to="/" toText="Back to dashboard" />
      )}
    </div>
  )
}

export default TestOrdersList
