import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Settings } from '../icons'

const NavBar = () => {
  let history = useHistory()
  const { firstName, lastName, role } = useSelector((state) => state.auth.me)
  const greeting = role === 'PROVIDER' ? `Hi Dr. ${lastName}` : `Hi ${firstName}`

  return (
    <div className="NavBar">
      <div className="date-greeting">
        <div className="date">{moment().format('dddd, MMMM Do')}</div>
        <div className="greeting-container">
          <div className="greeting view-title">{greeting}</div>
        </div>
      </div>
      <div className="settings icon-button" onClick={() => history.push('/settings')}>
        <Settings />
      </div>
    </div>
  )
}

export default NavBar
