import { useQuery } from 'react-query'
import { protectedRequest } from '../requests/api'

export const getPatientById = async (_, patientId) => protectedRequest('GET', `patients/${patientId}`)

export const toggleTestOrder = async (_, patientId, testId, status) => protectedRequest('PUT', `patients/${patientId}/test/order/${testId}/status/${status}`)

export const useToggleTestOrder = (patientId, testId, status) => {
  return useQuery(['toggleTestOrder', patientId, testId, status], toggleTestOrder)
}
export const usePatient = (patientId) => {
  return useQuery(['patient', patientId], getPatientById)
}
