import React from 'react'
import Icon from './Icon'

const Results = (props) => (
  <Icon viewBox="0 0 28 32" className="fill-none" {...props}>
    <rect x={0.98} y={3.429} width={23.41} height={27.592} rx={0.1} stroke="#00529B" strokeWidth={1.959} />
    <mask id="a" fill="#fff">
      <rect x={4.42} width={16.529} height={7.673} rx={1.633} />
    </mask>
    <rect
      x={4.42}
      width={16.529}
      height={7.673}
      rx={1.633}
      fill="#fff"
      strokeWidth={3.592}
      mask="url(#a)"
      className="stroke primary"
    />
    <path
      className="fill tertiary"
      d="M4.613 10.286h13.454v1.143H4.613zM4.613 13.388h13.454v1.143H4.613zM4.613 16.49h13.454v1.143H4.613zM4.613 19.592h13.454v1.143H4.613zM4.613 22.694h13.454v1.143H4.613z"
    />
    <path
      d="M13.562 21.027l-.161-.132-.16.135-2.403 2.041-.224.191.224.19 6.73 5.718.162.138.162-.138 11.699-9.938.224-.19-.224-.19-2.404-2.043-.16-.136-.162.135-9.136 7.627-4.167-3.408z"
      className="fill secondary"
      stroke="#fff"
      strokeWidth={0.5}
    />
  </Icon>
)

export default Results
