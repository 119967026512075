import { protectedRequest } from '../requests/api'

export const createStripeSession = appointmentId =>
  protectedRequest('GET', `stripe/checkout/${appointmentId}`).then(res => res.data)

export const getStripeConnectionParams = () => protectedRequest('GET', `stripe/connect`).then(res => res.data)

export const validateStripeConnectionOauth = (state, code) =>
  protectedRequest('POST', `stripe/connect/oauth`, { state, code }).then(res => res.data)

export const validateStripeCheckout = (appointmentId, stripeStateToken) =>
  protectedRequest('POST', `stripe/checkout/success`, { stripeStateToken, appointmentId }).then(res => res.data)