import React from 'react'
import { Logo } from '../../components/general'
import ForgotForm from '../../components/forms/ForgotForm'

const Forgot = () => {
  return (
    <div className="View Login">
      <div className="section tight">
        <Logo />
      </div>
      <ForgotForm />
    </div>
  )
}

export default Forgot
