import React, { useEffect, useState } from 'react'

import { NavBar } from '../../../../components/ui'
import { Back } from '../../../../components/general'
import { getSquareConnectionParams } from '../../../../utils/hooks/useSquare'

const getStripeConnectParams = (location, history, setMessage, setDomain, setClientId) => () => {
  getSquareConnectionParams()
    .then((response) => {
      setClientId(response.clientId)
      setDomain(response.domain)
    })
    .catch((e) => setMessage(e?.response?.data?.error))
}

const SquareConnect = (location, history) => {
  const [message, setMessage] = useState()
  const [domain, setDomain] = useState()
  const [clientId, setClientId] = useState()
  useEffect(getStripeConnectParams(location, history, setMessage, setDomain, setClientId), [])

  const stripeUrl =
    domain && clientId
      ? 'https://' + domain + '/oauth2/authorize?client_id=' + clientId + '&scope=PAYMENTS_READ%20PAYMENTS_WRITE'
      : ''

  return (
    <div className="View">
      <div className="section">
        <Back />
      </div>
      <NavBar />

      <div className="section">
        <div className="section-title">Square connect</div>
        {message && <div>{message}</div>}
        <p>
          {stripeUrl && (
            <a rel="noopener noreferrer" href={stripeUrl} className="stripe-button-el">
              <span>Connect</span>
            </a>
          )}
        </p>
      </div>
    </div>
  )
}

export default SquareConnect
