import { useQuery } from 'react-query'
import { protectedRequest } from '../requests/api'

const getPatients = async _ => {
  return protectedRequest('GET', 'patients/')
}

export default function usePatients() {
  return useQuery(['patients'], getPatients)
}
