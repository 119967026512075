import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import App from './App'
import * as serviceWorker from './serviceWorker'
const env = process.env.NODE_ENV || 'development'
const sentryDSN = process.env.SENTRY_DSN || 'https://4caf6b14f2a9458facc7405cd40a9f24@o376736.ingest.sentry.io/5197893'

Sentry.init({ dsn: sentryDSN, environment: env })

// TODO: Put in functions
if (document.location.protocol === 'http:' && document.location.hostname !== 'localhost') {
  document.location = document.location.href.replace(/^http:\/\//i, 'https://')
}

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
