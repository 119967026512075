import React from 'react'
import { isEmpty } from 'lodash'
import { Card, NavBar, LoadingSpinner } from '../../../components/ui'
import { AppointmentCard } from '../../../components/appointments'
import { useAppointment } from '../../../utils/hooks/useAppointment'
import { Appointments } from '../../../components/icons'
import Users from '../../../components/icons/Users'

const DoctorDashboard = () => {
  const { status, data } = useAppointment()
  const hasNextAppointment = !isEmpty(data?.data)

  return (
    <div className="DoctorDashboard View">
      <NavBar />
      <>
        {status === 'loading' ? (
          <div className="section">
            <LoadingSpinner />
          </div>
        ) : (
          hasNextAppointment && (
            <div className="section">
              <div className="section-title">Upcoming Appointment</div>
              <AppointmentCard appointment={data.data} />
            </div>
          )
        )}
        <div className="section">
          {hasNextAppointment && <div className="section-title">More</div>}
          <Card to="/appointments">
            <Appointments />
            <div>My Appointments</div>
          </Card>
          <Card to="/patients">
            <Users />
            <div>My Patients</div>
          </Card>
        </div>
      </>
    </div>
  )
}

export default DoctorDashboard
