import React from 'react'
import { Back } from '../general'

const AppointmentHeader = ({ patient, back }) => {
  return (
    <div className="AppointmentHeader section header">
      {back && <Back />}
      <div className="view-title">
        {patient.firstName} {patient.lastName}
      </div>
    </div>
  )
}

export default AppointmentHeader

AppointmentHeader.defaultProps = {
  back: true,
}
