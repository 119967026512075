import { useQuery } from 'react-query'
import { protectedRequest } from '../requests/api'

export const getResultsById = async (_, resultId) => protectedRequest('GET', `results/${resultId}`)

const getResults = async (_) => {
  return protectedRequest('GET', 'results/')
}

export const useResults = (resultId) => {
  const callToUse = resultId ? getResultsById : getResults
  return useQuery(['results', resultId], callToUse)
}
