import React from 'react'
import { Formik } from 'formik'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { logInAction } from '../../resources'
import { Field } from '../fields'

const LoginForm = ({ isPhilips, philipsAppUrl }) => {
  const dispatch = useDispatch()
  const logIn = (values) => dispatch(logInAction(values))

  const initialValues = {
    email: '',
    password: '',
  }

  const loginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('required'),
    password: Yup.string().required('required'),
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    logIn(values)
      .then(() => {})
      .catch((response) => {
        const errors = get(response, 'response.data.error', 'Error')
        setSubmitting(false)
        setErrors(errors)
      })
  }

  return (
    <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={onSubmit}>
      {({ handleSubmit, errors, isSubmitting, isValid, dirty }) => (
        <form className="Form section tight columns" onSubmit={handleSubmit}>
          <div className="section-title">Login</div>
          <div className="inner-section stretch form-fields">
            <Field name="email" placeholder="Email" error={errors.name} />
            <Field name="password" type="password" error={errors.name} placeholder="Password" />
            <div className="forgot">
              <Link className=" link small" to="/forgot">
                Forgot Password?
              </Link>
            </div>
            <button className={classNames('button primary', { inactive: !(isValid && dirty) })} type="submit">
              {!isSubmitting ? 'Log In' : 'Logging In...'}
            </button>
            <div className="switch-form">
              New to StreamMD?
              <Link className="link medium" to="/signup">
                Sign Up
              </Link>
            </div>
            {errors.message && <div className="error-message">{errors.message}</div>}
            {/* {!isPhilips && (
              <div className="switch-form">
                Philips Partner?
                <a href={`${philipsAppUrl}/login`} className="link medium">
                  Please Click Here
                </a>
              </div>
            )} */}
          </div>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
