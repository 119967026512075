import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { withAppointment } from './AppointmentProvider'
import { AppointmentHeader, CancelAppointment, DoctorNotes } from '../../../../components/appointments'
import { VisibilityIcon } from '../../../../components/icons'
import { ENV } from '../../../../config'


const PreAppointment = ({ appointmentId, when, patient, patientData }) => {
  const { role } = useSelector((state) => state.auth.me)
  const isAppointmentSoon = moment().diff(moment(when), 'minutes') >= -15 || ENV === 'development' //TODO: Handle timezone
  return (
    <div className="PreAppointment View">
      {/* TODO: Show opposite persons name */}
      <AppointmentHeader patient={patient} />
      {/* TODO: Show date */}
      {role === 'PROVIDER' ? (
        <>
          <DoctorNotes patientData={patientData} canEdit={false} />
          <div className="section fixed bottom footer">
            {/* Check if refunded or cancelled to show disabled or not */}
            <div className="button-explination top">
              *You can start your appointment within 15 minutes of the scheduled time.
            </div>
            <Link
              className={classNames('button tall call-button', { disabled: !isAppointmentSoon })}
              to={`/appointment/${appointmentId}`}
            >
              <div>Start Appointment</div>
              <div className="icon-container">
                <VisibilityIcon />
              </div>
            </Link>
            <CancelAppointment isDoctor={true} when={when} appointmentId={appointmentId} />
          </div>
        </>
      ) : (
        <>
          <div className="section">
            <div className="section-title">Your Appointment</div>
            <Link
              className={classNames('button tall call-button', { disabled: !isAppointmentSoon })}
              to={`/appointment/${appointmentId}`}
            >
              <div>Join Appointment</div>
              <div className="icon-container">
                <VisibilityIcon />
              </div>
            </Link>
            <div className="button-explination">
              *You can join your appointment within 15 minutes the scheduled time.
            </div>
          </div>
          {/* TODO Information about when to join */}
          {/* TODO Tips for call */}
          <div className="section bottom stretch">
            <CancelAppointment isDoctor={false} when={when} appointmentId={appointmentId} />
          </div>
        </>
      )}
    </div>
  )
}

export default withAppointment(PreAppointment)
