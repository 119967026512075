import React from 'react'
import { Back } from '../../../components/general'
import TrackingNumberForm from './TrackingNumberForm'

const TestOrderShipments = () => {
  return (
    <div className="View TestOrderShipment">
      <div className="section header">
        <Back to="/" />
      </div>
      <div className="section column stretch">
        <div className="section-title">Update Test Order Shipment</div>
        <TrackingNumberForm />
      </div>
    </div>
  )
}

export default TestOrderShipments
