import React from 'react'
import * as Sentry from '@sentry/browser'
import { Logo } from '../general';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { eventId: null }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    return !hasError ? (
      children
    ) : (
      <div className="Error View">
        <div className="section">
          <Logo />
        </div>
        <div className="section">
          <div className="section-title">Some error occurred</div>
          Please{' '}
          <button
            onClick={e => {
              Sentry.showReportDialog({ eventId: this.state.eventId })
            }}
            className="text-link"
          >
            let us know
          </button>{' '}
          what happened.
        </div>
      </div>
    )
  }
}

export default ErrorBoundary
