import React from 'react'
import { useDispatch } from 'react-redux'
import { Back } from '../../components/general'
import { logOutAction } from '../../resources'

const Settings = () => {
  const dispatch = useDispatch()
  const logout = () => dispatch(logOutAction())
  return (
    <div className="Settings View">
      <div className="section header">
        <Back />
      </div>
      {/* <div className="section">
      // TODO put in account info
      </div> */}
      <div className="section bottom stretch">
        <div className="button danger inverted" onClick={logout}>
          Logout
        </div>
      </div>
    </div>
  )
}

export default Settings
