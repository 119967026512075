import React from 'react'
import { get, isEmpty, sortBy } from 'lodash'
import usePatients from '../../utils/hooks/usePatients'
import { PatientCard } from '.'
import { LoadingSpinner, FallbackCard } from '../ui'

const PatientList = () => {
  const { status, data, error } = usePatients()

  if (status === 'loading') {
    return <LoadingSpinner />
  }

  if (status === 'error') {
    return <div>Error: {error.message}</div>
  }

  const patients = get(data, 'data')
  const sortedPatients = sortBy(patients, ['firstName', 'lastName'])

  return (
    <div className="Patients">
      {!isEmpty(sortedPatients) ? (
        sortedPatients.map((patient) => <PatientCard key={patient.id} patient={patient} />)
      ) : (
        <FallbackCard message="You do not have any patients yet..." to="/" toText="Back to dashboard" />
      )}
    </div>
  )
}

export default PatientList
