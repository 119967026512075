import React, { useState } from 'react'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'
import { cancelAppointment, checkRefundStatus, reassignAppointment } from '../../utils/hooks/useAppointment'
import { CANCEL_HOURS } from '../../config'
import { ConfirmModal } from '../ui/modals'
import { LoadingSpinner } from '../ui'

const POLLING_TIMEOUT = 1;
const REDIRECT_TIMEOUT = 1000;
const MAX_RETRIES = 2;

const getStatus = (history, counter, appointmentId) => async () => {
  console.log('Try to get appointment refund status', counter)
  const { refundStatus } = await checkRefundStatus(appointmentId)
  console.log('Refund status', refundStatus)
  if (refundStatus === 'completed' || counter >= MAX_RETRIES) {
    console.log('Number of requests', counter, refundStatus)
    setTimeout(() => history.push('/appointments'), REDIRECT_TIMEOUT)
  } else {
    setTimeout(getStatus(history, counter + 1, appointmentId), POLLING_TIMEOUT)
  }
}

const CancelAppointment = ({ appointmentId, isDoctor, when }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)
  //todo: display confirmation of refund.

  let history = useHistory()

  const cancel = () => {
    setIsCancelling(true)
    let promise
    if (isDoctor) {
      promise = reassignAppointment(appointmentId, when).then(() => {
        setTimeout(() => history.push('/appointments'), REDIRECT_TIMEOUT)
      })
    } else {
      promise = cancelAppointment(appointmentId).then((response) => {
        setTimeout(getStatus(history, 1, appointmentId), POLLING_TIMEOUT)
      })
    }

    promise.catch((err) => {
      console.log(err)
      //todo: show error message somewhere?
      setIsCancelling(false)
    })
  }

  const hours = moment(when).diff(moment(), 'hours')

  const canCancel = hours >= CANCEL_HOURS

  return (
    <div className="CancelAppointment button-container">
      <button className="button danger inverted" onClick={() => setIsModalOpen(true)} disabled={!canCancel}>
        Cancel Appointment
      </button>
      <div className="cancel-text">*You may cancel your appointment up to 24 hours before.</div>
      <ConfirmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title="Cancel Appointment"
        message="Are you sure you want to cancel this appointment?"
        type="danger"
        buttonText={isCancelling ? <LoadingSpinner className="small light" /> : 'Cancel'}
        buttonAction={cancel}
      />
    </div>
  )
}

export default CancelAppointment
