import React from 'react'
import { Card } from '../ui'

const PatientCard = ({ patient }) => {
  return (
    <Card className="PatientCard" to={`/appointments/user/${patient.id}`}>
      <div className="name">
        {patient.firstName} {patient.lastName}
      </div>
    </Card>
  )
}

export default PatientCard
