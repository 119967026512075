import React from 'react'
import Icon from './Icon'

const QuestionMarkFilled = (props) => (
  <Icon viewBox="0 0 32 32" className="fill" {...props}>
    <path
      fill="currentColor"
      d="M16 0C7.178 0 0 7.178 0 16s7.178 16 16 16 16-7.178 16-16S24.822 0 16 0zm0 26a2 2 0 1 1-.001-3.999A2 2 0 0 1 16 26zm4.926-11.645c-.958 1.142-2.715 2.138-3.374 3.144-.385.586-.382 1.267-.382 1.908h-2.38c0-.736-.051-1.814.486-2.794.323-.591.89-1.202 1.699-1.833 1.605-1.267 2.282-1.926 2.282-3.277 0-1.504-1.123-2.112-2.634-2.112-.996 0-2.099.152-4.054 1.117l-1.02-2.136A10.68 10.68 0 0 1 16.829 7c1.586 0 2.844.388 3.775 1.165S22 10.014 22 11.382c0 1.149-.312 2.066-1.074 2.973z"
    />
  </Icon>
)

export default QuestionMarkFilled
