import { useQuery } from 'react-query'
import { protectedRequest } from '../requests/api'

const getTestOrders = async (_) => {
  return protectedRequest('GET', 'testOrder')
}

export const createTestOrder = (data) =>
  protectedRequest('POST', `testOrder/shipping/address`, data).then((res) => res.data)

export const updateTestOrderTrackingNumber = (testOrderId, data) =>
  protectedRequest('POST', `testOrder/shipping/tracking/${testOrderId}`, data).then((res) => res.data)

export default function useResultsReview() {
  return useQuery(['testOrders'], getTestOrders)
}
