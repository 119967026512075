import React from 'react'
import { useHistory } from 'react-router-dom'
import { Wizard } from '../wizard/'
// import { useDispatch, useSelector } from 'react-redux'
import { useSelector } from 'react-redux'

import {
  SYMPTOM_QUESTIONS,
  EXPOSURE_QUESTIONS,
  MEDICAL_HISTORY_QUESTIONS,
  SYSTEM_QUESTIONS,
  // ALGO_OPTIONS,
  // processIntakeData,
} from '../../utils/constants/intake-questions'
// import { isNull } from 'lodash'
// import { validateTokenAction } from '../../resources'

const INTAKE_WIZARD = [SYMPTOM_QUESTIONS, EXPOSURE_QUESTIONS, MEDICAL_HISTORY_QUESTIONS, SYSTEM_QUESTIONS]

const IntakeWizard = () => {
  let history = useHistory()
  // const dispatch = useDispatch()
  // const validateToken = (hasToken) => dispatch(validateTokenAction(hasToken))
  const { dob } = useSelector((state) => state.auth.me)

  // TODO: Generate this automatically
  const initialValues = {
    exposure_people: '',
    note: '',
    height: '',
    weight: '',
    symptoms: [],
    symptoms_description: '',
    vaccines: [
      {
        manufacturer: '',
        date: '',
      },
      {
        manufacturer: '',
        date: '',
      },
    ],
    medication: [{ drug: '', dosage: '' }],
    allergies: [{ allergy: '', reaction: '' }],
    blood_pressure: { systolic: '', diastolic: '' },
    medical_history: {
      diabetes: '',
    },
    review_of_systems: {
      constitutional: {
        fever: '',
        chills: '',
      },
      head_eyes_ears_nose_throat: {
        vision_problem: '',
        decreased_hearing: '',
      },
      cardiovascular: {
        chest_pain: '',
        palpitations: '',
      },
      respiratory: {
        cough: '',
        rapid_breathing: '',
      },
      gastrointestinal: {
        abdominal_pain: '',
        blood_in_stool: '',
      },
    },
    social_history: {
      smoke: '',
    },
    surgical_history: '',
  }

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    // const key = processIntakeData({ ...values, dob })
    // const { level } = ALGO_OPTIONS[key]
    localStorage.setItem('intake', JSON.stringify({ ...values, version: 1, when: Date.now(), dob }))
    // if (level > 2) {
    //   history.push('/emergency')
    // } else {
    // const { street, street2, state, city, zipcode } = values
    // await saveAddress({ street, street2, state, city, zipcode })

    // let token = localStorage.getItem('token')
    // await validateToken(!isNull(token))

    history.push('/schedule?state=CA')
    // }
  }

  return <Wizard steps={INTAKE_WIZARD} initialValues={initialValues} onSubmit={onSubmit} />
}

export default IntakeWizard
