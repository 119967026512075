import React from 'react'
import { useRecorder } from '../../utils/hooks/useRecorder'
import { useEffect } from 'react'
import classNames from 'classnames'
import { MicIcon, MicOffIcon } from '../icons'

const RecorderInput = ({ field: { name }, form: { setFieldValue, setFieldError } }) => {
  const { transcription, transcriptionError, startRecorder, stopRecorder, isRecording } = useRecorder()

  useEffect(() => {
    const setValue = (value) => setFieldValue(name, value)
    const setError = (error) => setFieldError(name, error)

    if (transcriptionError) {
      setError(transcriptionError)
    }

    if (transcription) {
      setValue(transcription)
    }
  }, [transcription, transcriptionError, name, setFieldError, setFieldValue])

  const toggleRecorder = () => {
    if (isRecording) stopRecorder()
    else startRecorder()
  }

  return (
    <div className={classNames('RecorderInput', isRecording && 'active')} onClick={toggleRecorder}>
      {isRecording ? <MicIcon /> : <MicOffIcon />}
    </div>
  )
}

export default RecorderInput
