import React from 'react'
import Icon from './Icon'

const Dizziness = (props) => (
  <Icon viewBox="0 0 32 32" className="stroke fill-none" {...props}>
    <path
      d="M1 29c3.42-4.858 8.867-8 15-8 6.134 0 11.58 3.142 15 8"
      className="stroke tertiary"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path d="M12 3.936A9 9 0 1 1 7.223 14" className="stroke primary" strokeWidth={2} strokeLinejoin="round" />
    <path d="M7.5 1L3 7h6l-4.5 6" className="stroke secondary" strokeWidth={2} />
    <path d="M22 9l-4 4h4M10 9l4 4h-4" className="stroke secondary" strokeWidth={2} strokeLinejoin="bevel" />
  </Icon>
)

export default Dizziness
