import React from 'react'

export const TERMS = {
  title: 'StreamMD TERMS',
  body: [
    <div className="Policy c14">
      <p className="c1">
        <span className="c4">StreamMD Terms and Conditions</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          These Terms and Conditions (&ldquo;Terms and Conditions&rdquo;) form a legal agreement between StreamMD CA,
          P.C. and you, the individual (&ldquo;you&rdquo; and &ldquo;your&rdquo;) concerning your access to and use of
          the various services offered through the Websites and the Platforms (as defined below). Use of any of the
          Websites or Platforms constitutes your acceptance of these Terms and Conditions and the Web and Mobile Privacy
          Policy.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c9">
          StreamMD CA, P.C. on its own behalf and on behalf of its affiliates and/or wholly owned subsidiaries
          (collectively referred to as &ldquo;StreamMD&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;, or
          &ldquo;our&rdquo;), StreamMD makes certain telehealth related information available to you and/or facilitates
          your access to telemedicine and expert medical services (&ldquo;StreamMD Health Services&rdquo;). StreamMD
          operates (i) various publicly available websites, including without limitation,{' '}
        </span>
        <span className="c9 c15">
          <a
            className="c8"
            href="https://www.google.com/url?q=http://www.streammd.com&amp;sa=D&amp;ust=1589181669158000"
          >
            www.streammd.com
          </a>
        </span>
        <span className="c0">
          &nbsp;(collectively, &ldquo;Websites&rdquo;) and (ii) various web-based and mobile applications that require
          you to create an account in order to use a StreamMD Service (collectively, &ldquo;Platforms&rdquo;).
        </span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          By using the Websites and/or the Platforms, you expressly agree to these Terms and Conditions. If you do not
          agree to these Terms and Conditions, you should immediately cease all use of and access to all of the Websites
          and Platforms. Please print a copy of these Terms and Conditions for your records.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Modifications</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          StreamMD may in its sole discretion, without prior notice to you, revise these Terms and Conditions at any
          time. Should these Terms and Conditions change materially, StreamMD will update the Effective Date noted above
          and post a notice regarding the updated Terms and Conditions on the Websites. The amended Terms and Conditions
          will also appear when the Platforms are accessed by you and you will need to acknowledge your agreement to the
          amended Terms and Conditions prior to being able to continue to use the Platforms. If you do not agree to the
          terms of the amended Terms and Conditions, your sole and exclusive remedy is to discontinue your use of the
          Websites and Platforms and you will be deemed to have terminated these Terms and Conditions. Amended Terms and
          Conditions will be effective as of the date posted unless otherwise stated. By accessing or using the Websites
          and the Platforms after such changes are posted you agree and consent to all such changes.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Access to the platforms</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          You may access and use the Platforms via remote access connectivity. StreamMD grants you a limited,
          non-exclusive, non-sublicensable, revocable, non-transferable license to use the Platforms in accordance with
          these Terms and Conditions. In order to use the Platforms, you will be asked to register an account and create
          login information, including without limitation, username and passwords. You must safeguard your login
          information that you use to access the Platforms and you must not disclose this information to anyone. You
          must immediately notify StreamMD of any unauthorized use of your user account or of any other breach of
          security that you become aware of involving and relating to the Platforms by email to privacy@streammd.com.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">StreamMD&rsquo;s role</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          StreamMD is not an insurance provider nor are we a prescription fulfillment warehouse. StreamMD&rsquo;s role
          is limited to making certain telehealth related information available to you and/or facilitate your access to
          telemedicine and expert medical services. StreamMD is independent from healthcare providers who will be
          providing such telemedicine services to you through the Platform and is not responsible for such healthcare
          providers&#39; acts, omissions or for any content of the communications made by them. StreamMD does not engage
          in the practice of medicine or provide any other health services.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Your representations and warranties</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          By registering on the Platforms, you represent and warrant the following: (i) you are at least eighteen (18)
          years of age, (ii) you have the legal ability and authority to enter into these Terms and Conditions with
          StreamMD, (iii) the information you have provided to StreamMD in your registration is accurate and complete,
          (iv) you will comply with any and all laws applicable to your use of the Websites and Platforms, (v) you will
          not interfere with a third party&#39;s use and enjoyment of the Websites and Platforms, (vi) you will not
          interfere with or disrupt StreamMD&rsquo;s or its vendors&#39; security measures, (vii) if any information you
          provide to StreamMD becomes inaccurate, incomplete or otherwise false or misleading, you will immediately
          notify StreamMD, (viii) you acknowledge that access to the healthcare services provided through the Platforms
          are not &ldquo;insured services&rdquo; under any provincial health plans, (ix) you acknowledge that any fees
          paid by you or by any other persons on your behalf are not provided in exchange for any undertaking by
          StreamMD or its representatives that such healthcare services will be made available to you, and (x) you are
          accessing the Websites and Platforms for yourself or a child under the age of eighteen for whom you are the
          legal guardian.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Termination</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          If you violate these Terms and Conditions, your ability to use the Websites and/or Platforms will be
          terminated. &nbsp;StreamMD may, in its sole discretion, terminate your access to the Websites and/or
          Platforms, or any portion thereof, for any reason whatsoever without prior notice. These actions are in
          addition to any other right or remedy StreamMD may have available at law. Further, StreamMD shall not be
          liable to you or any third party for any such termination or discontinuance. You may terminate these Terms and
          Conditions by ceasing to access and use the Websites and Platforms. Upon any termination of these Terms and
          Conditions you must immediately cease use of the Websites and Platforms. To the extent permitted by applicable
          law, the disclaimers, limitations on liability, termination and your warranties and indemnities shall survive
          any termination of these Terms and Conditions.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Prohibited uses</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">In using the Websites and Platforms, you agree not to:</span>
      </p>
      <ul className="c3 lst-kix_w83b74ayvp9z-0 start">
        <li className="c2">
          <span className="c0">
            Send or otherwise transmit to or through the Websites and Platforms any unlawful, infringing, harmful,
            harassing, defamatory, threatening, hateful or otherwise objectionable material of any kind, any material
            that can cause harm or delay to the Websites and Platforms or computers of any kind, and any unsolicited
            advertising, solicitation or promotional materials;
          </span>
        </li>
      </ul>
      <ul className="c3 lst-kix_yo71qc2kshxw-0 start">
        <li className="c2">
          <span className="c0">Misrepresent your identity or affiliation in any way;</span>
        </li>
        <li className="c2">
          <span className="c0">
            Restrict or inhibit any person from using the Websites and Platforms, disclose personal information obtained
            from the Websites and Platforms or collect information about users of the Websites and Platforms;
          </span>
        </li>
      </ul>
      <ul className="c3 lst-kix_pfuljev6iu9o-0 start">
        <li className="c2">
          <span className="c0">
            Reverse engineer, disassemble or decompile any section or technology on the Websites and Platforms, or
            attempt to do any of the foregoing;
          </span>
        </li>
      </ul>
      <ul className="c3 lst-kix_ocsecbsqknzn-0 start">
        <li className="c2">
          <span className="c0">
            Gain unauthorized access to the Websites and Platforms, to other users&#39; accounts, names, personally
            identifiable information or other information, or to other computers or websites connected or linked to the
            Websites and Platforms;
          </span>
        </li>
      </ul>
      <ul className="c3 lst-kix_eznulzp42u4v-0 start">
        <li className="c2">
          <span className="c0">
            Launch or use any automated system, including without limitation, &ldquo;robots,&rdquo;
            &ldquo;spiders,&rdquo; or &ldquo;offline readers,&rdquo; that access the Websites and Platforms in a manner
            that sends more request messages to our servers in a given period of time than a human can reasonably
            produce in the same period by using a conventional web browser;
          </span>
        </li>
      </ul>
      <ul className="c3 lst-kix_l31fmbsmcfoj-0 start">
        <li className="c2">
          <span className="c0">
            Send or otherwise transmit to or through the Websites and Platforms chain letters, unsolicited messages,
            so-called &ldquo;spamming&rdquo; or &ldquo;phishing&rdquo; messages, or messages marketing or advertising
            goods and services;
          </span>
        </li>
      </ul>
      <ul className="c3 lst-kix_6my9y4cmtp5r-0 start">
        <li className="c2">
          <span className="c0">
            Post, transmit or otherwise make available any virus, worm, spyware or any other computer code, file or
            program that may or is intended to damage or hijack the operation of any hardware, software or
            telecommunications equipment;
          </span>
        </li>
        <li className="c2">
          <span className="c0">Violate any applicable laws or regulations in any way;</span>
        </li>
        <li className="c2">
          <span className="c0">
            Alter or modify any part of the content or services offered on or through the Websites and Platforms;
          </span>
        </li>
        <li className="c2">
          <span className="c0">
            Allow any other person to use the Platforms with your registration or login information;
          </span>
        </li>
        <li className="c2">
          <span className="c0">
            Breach or otherwise circumvent StreamMD&rsquo;s security or authentication measures; and
          </span>
        </li>
        <li className="c2">
          <span className="c0">Assist or permit any persons in engaging in any of the activities described above.</span>
        </li>
      </ul>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Intellectual property rights and content</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          Opt Health, Inc. is the sole and exclusive owner of the Websites and Platforms and any and all copyright,
          patent, trademark, trade secret and other ownership and intellectual property rights contained or used in and
          to the Websites and Platforms and any related materials and documentation. No title or ownership of the
          Websites and Platforms or any portion thereof is transferred to you hereunder. Opt Health, Inc. and StreamMD
          reserve all rights not expressly granted hereunder. You agree not to change or delete any copyright or
          proprietary notice related to materials downloaded from the Websites and/or Platforms.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          You may provide input, comments or suggestions regarding the Websites and Platforms or StreamMD
          (&ldquo;Feedback&rdquo;). You acknowledge and agree that StreamMD may use any Feedback without any obligation
          to you and you hereby grant StreamMD a worldwide, perpetual, irrevocable, royalty-free, transferable license
          to reproduce, display, perform, distribute, publish, modify, edit or otherwise use such Feedback as StreamMD
          may deem appropriate, without restriction, for any and all commercial and/or non-commercial purposes, in its
          sole discretion.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">External links</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          The Websites and Platforms may contain links to third-party websites. Linked sites are not under the control
          of StreamMD, and StreamMD is not responsible for the content of any linked site. &nbsp;Links are provided as a
          convenience only, and a link does not imply that StreamMD endorses, sponsors, or is affiliated with the linked
          site. &nbsp;Your use of third-party websites is at your own risk and subject to the terms and conditions of
          use for such sites; these Terms do not apply to other websites. StreamMD disclaims any and all liability for
          any information, including but without limitation, any medical and health treatment information set forth on
          linked sites.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">No endorsements</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          Reference to any product, recording, event, process, publication, service, or offering of any third party by
          name, trade name, trademark, service mark, company name or otherwise does not constitute or imply the
          endorsement or recommendation of such by StreamMD. Any views expressed by third parties on the Websites and
          Platforms are solely the views of such third party and StreamMD assumes no responsibility for the accuracy or
          veracity of any statement made by such third party.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Indemnification and limitation of liability</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          You agree to defend, indemnify and hold StreamMD, Opt Health, Inc., and all of their respective officers,
          directors, employees, shareholders, affiliates, third-party contractors, agents, licensors and suppliers (each
          a &ldquo;StreamMD Party&rdquo; and collectively &ldquo;StreamMD Parties&rdquo;), harmless from and against any
          claims, actions or demands, losses, liabilities, damages, costs, expenses and settlements (including without
          limitation reasonable attorney and accounting fees), resulting from or alleged to result from, directly or
          indirectly, your (a) violation of these Terms and Conditions; (b) access to or use of the Platform and
          Websites; and (c) provision of other disclosure to StreamMD of any other information or data and the use of
          same by StreamMD or other StreamMD Party as contemplated hereunder.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          IN NO EVENT SHALL STREAMMD OR OPT HEALTH, INC. BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE,
          INCIDENTAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM ANY LOSS OF USE,
          LOSS OF PROFITS, LITIGATION, OR ANY OTHER PECUNIARY LOSS, WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING
          NEGLIGENCE), PRODUCT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE WEBSITES OR
          PLATFORMS OR THE PROVISION OF OR FAILURE TO MAKE AVAILABLE ANY SUCH PRODUCTS, GOODS, OR SERVICES, EVEN IF
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          StreamMD shall not be liable for any failure to perform its obligations hereunder where the failure results
          from any cause beyond StreamMD&rsquo;s reasonable control, including, without limitation, mechanical,
          electronic, or communications failure or degradation.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          The terms of these Terms and Conditions that limit liability reflect an informed voluntary allocation of risk;
          such allocation represents a material part of these Terms and Conditions. You agree that the limitations of
          liabilities set out in these Terms and Conditions are fair and reasonable in the circumstances.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Disclaimer</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          The Websites and Platforms are provided on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis and
          without warranties of any kind, either express or implied. To the fullest extent permissible pursuant to
          applicable law, StreamMD disclaims all representations, warranties, and conditions, express or implied,
          including, but not limited to, implied condition or warranties of merchantability and fitness for a particular
          purpose. StreamMD does not warrant that the Websites and Platforms will be uninterrupted or error-free, that
          defects will be corrected or that the Websites and Platforms or the server that makes it available are free of
          viruses or other harmful components.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          StreamMD makes no guarantees, and disclaims any implied warranty or representation about its accuracy,
          relevance, timeliness, completeness, or appropriateness of any content posted on the Website and Platforms for
          a particular purpose. &nbsp;StreamMD assumes no liability arising from or relating to the delay, failure,
          interruption, or corruption of any data or other information transmitted in connection with use of the
          Websites and/or Platforms.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          Applicable law may not allow the exclusion of implied warranties, so the above exclusion may not apply to you.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Children</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          The Websites and Platforms are not intended for children under the age of 13. If you are under 13 years of
          age, please do not use or access the Websites or Platforms at any time or in any manner. By using the Websites
          or Platforms, you affirm that you are over the age of 13. StreamMD does not seek through the Websites or
          Platforms to gather personal information from or about persons under the age of 13 without the consent of a
          parent or guardian.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Choice of law and jurisdiction</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c9">
          These Terms and Conditions are governed by the laws of California. The parties irrevocably submit to the
          exclusive jurisdiction of the courts of the State of California in respect of all matters and disputes arising
          hereunder, and waive any defense of lack of personal jurisdiction in that jurisdiction. These Terms and
          Conditions are not governed by the{' '}
        </span>
        <span className="c9 c10">United Nations Convention on Contracts for the International Sale of Goods</span>
        <span className="c0">
          , the application of which is hereby expressly excluded. If any provision of these Terms and Conditions is
          found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not
          affect the validity of the remaining provisions of these Terms and Conditions, which shall remain in full
          force and effect.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Personal information and privacy policy</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c9">
          In these Terms and Conditions, &ldquo;personal information&rdquo; means any information about an identifiable
          individual, such as your name, email address, mailing address, gender, date of birth, any personal or
          protected health information, or, any data about you that you elect to provide electronically through the
          Websites and Platforms and any other information that identifies who you are. Personal Information will be
          used by StreamMD solely in accordance with these Terms and Conditions, and StreamMD&rsquo;s Web and Mobile{' '}
        </span>
        <span className="c9 c16">Privacy Policy</span>
        <span className="c0">&nbsp;(or as otherwise agreed upon between you and StreamMD in writing).</span>
      </p>
      <p className="c1">
        <span className="c0">
          You agree that StreamMD has the right to monitor and review your use of the Websites and Platforms from time
          to time, and to use &ldquo;cookies&rdquo;, &ldquo;log files&rdquo; and your &ldquo;browsing data&rdquo; in
          accordance with the Cookie Policy.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Waiver</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          No delay or omission by StreamMD to exercise any right or power it has under these Terms and Conditions or to
          object to the failure of any covenant of you to be performed in a timely and complete manner, shall impair any
          such right or power or be construed as a waiver of any succeeding breach or any other covenant. Any waivers by
          StreamMD must be in writing and signed by an authorized representative of StreamMD.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Entire agreement</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          These Terms and Conditions constitute the entire agreement between you and StreamMD as it relates to the
          access to, and use of, the Platform and Websites and the subject matter of these Terms and Conditions and
          supersede all prior or contemporaneous agreements, negotiations, representations and proposals, written or
          oral between StreamMD and you.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Electronic documents</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          This electronic document, and all other electronic documents referred to or incorporated herein, will be: (a)
          deemed for all purposes to be a &ldquo;writing&rdquo; or &ldquo;in writing&rdquo;, and to comply with all
          statutory, contractual, and other legal requirements for a writing; and (b) legally enforceable as a signed
          agreement. A printed version of these Terms and Conditions and any notice given in electronic form shall be
          admissible in judicial proceedings or administrative proceedings based upon or relating to these Terms and
          Conditions to the same extent and subject to the same conditions as other business documents and records
          originally generated and maintained in printed form.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Assignment</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          These Terms and Conditions are personal to you, and are not assignable, transferable, or sublicensable by you
          except with StreamMD&rsquo;s prior written consent. StreamMD may assign, transfer, or delegate any of its
          rights and obligations hereunder without your consent.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Language</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c9">
          English shall be the language of these Terms and the parties waive any right to use and rely upon any other
          language or translations
        </span>
        <span className="c9 c10 c11">.</span>
      </p>
      <p className="c1 c5">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">Contact / Notices</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          If you have any questions about the Terms and Conditions, or need to provide notice to, or communicate with,
          StreamMD under the Terms and Conditions, please contact StreamMD using the following contact details:
        </span>
      </p>
      <p className="c6 c5">
        <span className="c0"></span>
      </p>
      <p className="c6">
        <span className="c0">StreamMD</span>
      </p>
      <p className="c6">
        <span className="c9">595 Pacific Ave, 4</span>
        <span className="c9 c12">th</span>
        <span className="c0">&nbsp;Floor</span>
      </p>
      <p className="c6">
        <span className="c0">San Francisco, CA 94133</span>
      </p>
      <p className="c1">
        <span className="c0">privacy@streammd.com</span>
      </p>
      <p className="c1 c5">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          StreamMD may provide notices or communications to you on the Websites and/or Platforms and you agree that such
          notices shall constitute notice to you whether or not you actually access the notice.
        </span>
      </p>
      <p className="c5 c7">
        <span className="c11 c13"></span>
      </p>
    </div>,
  ],
}
