import React from 'react'
import { useHistory } from 'react-router-dom'
import { withAppointment } from '../../views/protected/doctor/appointment/AppointmentProvider'
import { Formik } from 'formik'
import { DoctorNotesSection, PlanAndAssessment } from '.'
import {
  CHIEF_COMPLAINT,
  PRESENT_ILLNESS,
  PAST_MEDICAL_HISTORY,
  REVIEW_OF_SYSTEMS,
  PAST_SURGICAL_HISTORY,
  FAMILY_HISTORY,
  SOCIAL_HISTORY,
  ALLERGIES,
  MEDICATION,
  VITALS,
  processIntakeData,
  getBMI,
} from '../../utils/constants/intake-questions'
import classNames from 'classnames'
// import { toggleTestOrder } from '../../utils/hooks/usePatient'
// import { TestOrderStatus } from '../../utils/constants/test-order'
import { endAppointment } from '../../utils/hooks/useAppointment'
import { Field } from '../fields'

const DoctorNotes = ({ appointmentId, patientData, canEdit }) => {
  // const initialValues = { ...patientData, bmi: getBMI(patientData) }
  // let initialValues = JSON.parse(localStorage.getItem('intake'))
  // const algoKey = processIntakeData(initialValues)
  const history = useHistory()

  // if(status === 'success'){}

  const sections = [
    CHIEF_COMPLAINT,
    PRESENT_ILLNESS,
    PAST_MEDICAL_HISTORY,
    REVIEW_OF_SYSTEMS,
    PAST_SURGICAL_HISTORY,
    FAMILY_HISTORY,
    SOCIAL_HISTORY,
    ALLERGIES,
    MEDICATION,
    VITALS,
  ]

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true)
    try {
      await endAppointment(appointmentId, values)
      console.log('DONE', values)
      return history.push(`/dashboard`)
    } catch (err) {
      console.log(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className="DoctorNotes">
      {/* {initialValues && (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit, errors, isSubmitting, isValid, dirty }) => (
            <form className="Form columns stretch" onSubmit={handleSubmit}>
              <div className="section">
                <div className="section-title">Notes</div>
                <div className="note-wrapper">
                  <PlanAndAssessment algoKey={algoKey} canEdit={canEdit} />
                  {canEdit && (
                    <Field
                      name="physical_exam"
                      label="Physical Exam"
                      placeholder="Enter patient's physical exam assessment"
                      component="textarea"
                      type="file"
                      accept="audio/*"
                      withRecorder={canEdit}
                      capture
                    />
                  )}
                  {sections.map((section, index) => {
                    const editable = canEdit && section[0].canEdit
                    return <DoctorNotesSection key={index} canEdit={editable} questions={section} />
                  })}
                </div>
              </div>
              {canEdit && (
                <div className="section fixed bottom">
                  <button
                    className={classNames('button primary', { inactive: !isValid })}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {isSubmitting ? 'Saving...' : 'Save & Finish'}
                  </button>
                </div>
              )}
            </form>
          )}
        </Formik>
      )} */}
    </div>
  )
}

export default withAppointment(DoctorNotes)

DoctorNotes.defaultProps = {
  canEdit: false,
}
