import React from 'react'
import Icon from './Icon'

const BloodCough = (props) => (
  <Icon viewBox="0 0 32 32" className="stroke fill-none" {...props}>
    <path d="M18.121 21.121a3 3 0 1 0-4.242 0L16 19l2.121 2.121z" className="fill primary" />
    <path
      d="M1 29c2.353-3.342 5.665-5.872 9.5-7.125M31 29c-2.353-3.342-5.665-5.872-9.5-7.125"
      className="stroke secondary"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path d="M20.127 20a9 9 0 1 0-8.254 0" className="stroke primary" strokeWidth={2} />
    <path d="M22 12a2 2 0 1 1-4 0M14 12a2 2 0 1 1-4 0" className="stroke secondary" strokeWidth={2} />
    <path d="M19 25a3 3 0 1 1-6 0c0-3 3-6 3-6s3 3 3 6z" className="stroke tertiary" strokeWidth={2} />
  </Icon>
)

export default BloodCough
