import React from 'react'
import { isEmpty } from 'lodash'
import { FallbackCard } from '../ui'
import { Appointments } from '../icons'

const ScheduleAppointmentPrompt = () => {
  const hasIntakeData = !isEmpty(localStorage.getItem('intake'))
  const to = hasIntakeData ? '/schedule?state=CA' : '/intake'
  return (
    <FallbackCard
      icon={Appointments}
      message="You do not have any upcoming appointments..."
      to={to}
      toText="Schedule an appointment"
    />
  )
}

export default ScheduleAppointmentPrompt
