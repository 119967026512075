import React from 'react'
import Icon from './Icon'

const Chills = (props) => (
  <Icon viewBox="0 0 32 32" className="stroke fill-none" {...props}>
    <path
      d="M28.728 24.485h-4.243m0 0v4.243m0-4.243L16 16M3.272 24.485h4.243m0 0v4.243m0-4.243L16 16M7.515 3.272v4.243m0 0H3.272m4.243 0L16 16M24.485 3.272v4.243m0 0h4.243m-4.243 0L16 16"
      className="stroke secondary"
      strokeWidth={2}
    />
    <path
      d="M13 1l3 3m0 0l3-3m-3 3v12M31 13l-3 3m0 0l3 3m-3-3H16M13 31l3-3m0 0l3 3m-3-3V16M1 13l3 3m0 0l-3 3m3-3h12"
      className="stroke primary"
      strokeWidth={2}
    />
  </Icon>
)

export default Chills
