import { useQuery } from 'react-query'
import { protectedRequest } from '../requests/api'

export const getAppointmentsById = async (_, appointmentId) =>
  protectedRequest('GET', `appointments/user/${appointmentId}`)

const getAppointments = async (_) => {
  return protectedRequest('GET', 'appointments/history')
}

export const useAppointments = (patientId) => {
  const callToUse = patientId ? getAppointmentsById : getAppointments
  return useQuery(['appointments', patientId], callToUse)
}
