import React from 'react'
import { useHistory } from 'react-router-dom'
import { Chevron } from '../icons'

const Back = ({ to }) => {
  let history = useHistory()
  const backAction = to ? () => history.replace(to) : () => history.goBack()
  return (
    <div className="Back link secondary" onClick={backAction}>
      <Chevron /> Back
    </div>
  )
}

export default Back
