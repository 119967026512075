import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { NavBar } from '../../../../components/ui'
import { Back } from '../../../../components/general'
import { getStripeConnectionParams } from '../../../../utils/hooks/useStripe';

const getStripeConnectParams = (location, history, setMessage, setToken, setClientId, setRedirectURI) => () => {
  getStripeConnectionParams()
    .then(response => {
      setToken(response.state)
      setClientId(response.clientId)
      setRedirectURI(response.redirectURI)
    })
    .catch(e => setMessage(e?.response?.data?.error))
}

const StripeConnect = (location, history) => {
  const { email } = useSelector(state => state.auth.me)
  const [message, setMessage] = useState()
  const [token, setToken] = useState()
  const [clientId, setClientId] = useState()
  const [redirectURI, setRedirectURI] = useState()
  useEffect(getStripeConnectParams(location, history, setMessage, setToken, setClientId, setRedirectURI), [])

  const stripeUrl =
    token && clientId && redirectURI
      ? 'https://connect.stripe.com/express/oauth/authorize?client_id=' +
        clientId +
        '&state=' +
        token +
        '&scope=read_write' +
        '&response_type=code' +
        '&suggested_capabilities[]=card_payments' +
        '&stripe_user[email]=' +
        (email || '') +
        '&redirect_uri=' +
        redirectURI
      : ''

  return (
    <div className="View">
      <div className="section">
        <Back />
      </div>
      <NavBar />

      <div className="section">
        <div className="section-title">Stripe connect</div>
        {message && <div>{message}</div>}
        <p>
          {stripeUrl && (
            <a rel="noopener noreferrer" href={stripeUrl} className="stripe-button-el">
              <span>Connect</span>
            </a>
          )}
        </p>
      </div>
    </div>
  )
}

export default StripeConnect
