import React, { useEffect } from 'react'
import {
  isBrowserChrome,
  isBrowserFirefox,
  isBrowserSafari,
  isIos,
  // isUnSupportedBrowser,
} from '../../utils/browser/browser.util'
// import BrowserNotSupportedComponent from './browserNotSupported.component'
import { get } from 'lodash'
import chromeInstructions from '../../assets/img/chrome-instructions.png'
import safariInstructions from '../../assets/img/safari-instructions.png'
import firefoxInstructions from '../../assets/img/firefox-instructions.png'

const getInstructionsText = () => {
  if (isBrowserChrome()) {
    return (
      <div>
        <div>Step 1. Click camera icon in your browser toolbar</div>
        <div>Step 2. Select Always allow access to camera and microphone</div>
        <div>Step 3. Click Done and refresh browser page</div>
      </div>
    )
  }
  if (isBrowserFirefox()) {
    return (
      <div>
        <div>Step 1. Click camera icon in your browser toolbar</div>
        <div>Step 2. Click x icon next to Blocked label in order to enable access to Microphone/Camera</div>
        <div>Step 3. Refresh your browser page</div>
      </div>
    )
  }
  if (isBrowserSafari()) {
    return (
      <div>
        <div>Step 1. Go to Safari => Preferences... => Websites</div>
        <div>Step 2. Select Camera/Microphone</div>
        <div>Step 3. Select StreamMD and select allow option</div>
        <div>Step 4. Close Preferences window</div>
        <div>Step 5. Refresh your browser page</div>
      </div>
    )
  }
}
const getInstructionsImg = () => {
  if (isBrowserChrome()) {
    return <img className="instruction-image" src={chromeInstructions} alt="" />
  }
  if (isBrowserFirefox()) {
    return <img className="instruction-image" src={firefoxInstructions} alt="" />
  }
  if (isBrowserSafari()) {
    return <img className="instruction-image" src={safariInstructions} alt="" />
  }
}

const askForPermissions = (setPermissions) => {
  window.OT.getUserMedia({ audio: false, video: false })
    .then(() => {
      setPermissions(true)
    })
    .catch(() => setPermissions(false))
}
const MediaPermissions = ({ permissions, setPermissions }) => {
  useEffect(() => {
    async function check() {
      if (navigator.permissions && navigator.permissions.query) {
        const videoPermission = await navigator.permissions.query({ name: 'camera' })
        const audioPermission = await navigator.permissions.query({ name: 'microphone' })

        if (get(audioPermission, 'state') === 'granted' || get(videoPermission, 'state') === 'granted') {
          setPermissions(true)
        }
      }
    }
    if (!isBrowserFirefox()) {
      check()
    }
  }, [setPermissions])

  if (!permissions) {
    return (
      <div className="MediaPermissions View section">
        <div className="section-title">
          We need permission to access your camera and microphone. After clicking the button below, please select Allow.
        </div>
        <div className="section-instructions">{!isIos() && getInstructionsImg()}</div>
        <div className="section-title">{!isIos() && getInstructionsText()}</div>

        <button className="button primary" type="submit" onClick={() => askForPermissions(setPermissions)}>
          Ok, ask permission.
        </button>
      </div>
    )
  } else {
    return null
  }
}

export default MediaPermissions
