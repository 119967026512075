import React from 'react'
import Icon from './Icon'

const Cough = (props) => (
  <Icon viewBox="0 0 32 32" className="stroke fill-none" {...props}>
    <path
      d="M20.4 13.867A3.216 3.216 0 0 1 19 11.2C19 9.433 20.401 8 22.13 8c1.73 0 3.13 1.433 3.13 3.2l-.001.12c.336-.079.686-.12 1.045-.12C28.898 11.2 31 13.349 31 16s-2.102 4.8-4.696 4.8c-.36 0-.709-.041-1.045-.12l.002.12c0 1.767-1.402 3.2-3.13 3.2C20.4 24 19 22.567 19 20.8c0-1.113.556-2.093 1.4-2.667M23 18H13M23 14H13"
      className="stroke primary"
      strokeWidth={2}
    />
    <path
      d="M8 0v2l3.87 5.419A1 1 0 0 1 11.057 9H7l2.128 2.838a1 1 0 0 1-.525 1.561l-3.131.895c-.877.25-.988 1.448-.173 1.856l2.807 1.403a1 1 0 0 1 .447 1.341L7 22c.167 2-.4 5-4 5H1v5"
      className="stroke tertiary"
      strokeWidth={2}
    />
  </Icon>
)

export default Cough
