import React from 'react'
import moment from 'moment'
import classNames from 'classnames'

const Slots = ({ onClick, slots = [], times = [] }) => (
  <div className="slot-container">
    {slots.map((slot) => (
      <div
        className={classNames('slot', { selected: times.includes(slot.time) })}
        onClick={() => onClick(slot)}
        key={slot.time}
      >
        {moment(slot.when).format('h:mm A')}
      </div>
    ))}
  </div>
)
export default Slots
