import React from 'react'
import Icon from './Icon'

const QuestionMark = (props) => (
  <Icon viewBox="0 0 34 32" className="fill-none" {...props}>
    <circle cx={16} cy={16} r={15} stroke="#23C48C" strokeWidth={2} />
    <circle cx={15.5} cy={23.5} r={1.5} fill="#002DDD" />
    <path
      d="M15.5 20c0-2.889 2.442-4.312 3.787-6.325A4.198 4.198 0 0 0 20 11.333C20 8.94 17.985 7 15.5 7S11 8.94 11 11.333c0 1.156.5 1.926.75 2.167"
      stroke="#002DDD"
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </Icon>
)

export default QuestionMark
