import React from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'
import Close from '../../icons/Close'

Modal.setAppElement('#root')

const StandardModal = ({ className, isModalOpen, setIsModalOpen, title, body, footer }) => {
  return (
    <Modal className={classNames('Modal', className)} isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
      <div className="header">
        <div className="modal-title">{title}</div>
        <div className="modal-close" onClick={() => setIsModalOpen(false)}>
          <Close />
        </div>
      </div>
      <div className="body">{body}</div>
      <div className="footer">{footer}</div>
    </Modal>
  )
}

export default StandardModal
