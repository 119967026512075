import React from 'react'
import moment from 'moment'
import { Card } from '../ui'

const ResultCard = ({ result }) => {
  return (
    <Card className="ResultCard" to={`/results/${result.id}`}>
      <div className="name">{moment(result.date).format('LL')}</div>
    </Card>
  )
}

export default ResultCard
