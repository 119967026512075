import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { get } from 'lodash'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetAction } from '../../resources'
import { Field } from '../fields'
import { useQueryParam, StringParam } from 'use-query-params';

import { ToolTip } from '../ui'

const ResetForm = ({ history, location }) => {
  const dispatch = useDispatch()
  const reset = (values, id, ex, h) => dispatch(resetAction(values, id, ex, h))
  const [message, setMessage] = useState()
  const [formError, setFormError] = useState('')
  const [isRedirecting, setIsRedirecting] = useState()
  const [id] = useQueryParam('id', StringParam);
  const [ex] = useQueryParam('ex', StringParam);
  const [h] = useQueryParam('h', StringParam);

  const initialValues = {
    password: '',
    passwordConfirm: '',
  }

  const resetMessagesAndErrors = () => {
    setMessage('')
    setFormError('')
  }

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    resetMessagesAndErrors()

    reset(values, id, ex, h)
      .then(response => {
        setMessage(response.data.message)
        setIsRedirecting('true')

        setTimeout(() => {
          history.push('/')
        }, 2000)
      })
      .catch(error => {
        const message = get(error, 'response.data.error', 'Error')
        setSubmitting(false)
        if (typeof message === 'string') {
          setFormError(message);
          return;
        }
        setErrors(message)
      })
  }

  const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(passwordRegex, {
        message: (
          <ToolTip
            text="invalid password"
            tipText="Passwords must be 8+ characters, 1+ uppercase, 1+ lowercase, 1+ numeric."
          />
        ),
        excludeEmptyString: true,
      })
      .required('required'),
    passwordConfirm: Yup.string()
      .matches(passwordRegex, {
        message: (
          <ToolTip
            text="invalid password"
            tipText="Passwords must be 8+ characters, 1+ uppercase, 1+ lowercase, 1+ numeric."
          />
        ),
        excludeEmptyString: true,
      })
      .test('passwords-match', 'Passwords must match', function yupTestFunction(value) {
        return this.parent.password === value;
      })
      .required('required'),
  })

  return (
    <Formik initialValues={initialValues} validationSchema={ResetPasswordSchema} onSubmit={onSubmit}>
      {props => (
        <form className="Form section columns" onSubmit={props.handleSubmit}>
          <div className="section-title">Reset Password</div>
          {message && <div className="success-message">{message}</div>}
          {formError && <div className="error-message">{formError}</div>}
          <div className="inner-section stretch form-fields">
            <Field name="password" type="password" placeholder="Password" error={props.errors.password} />
            <Field name="passwordConfirm" type="password" placeholder="Confirm Password" error={props.errors.passwordConfirm} />
            <button className="button primary" type="submit">
              {!props.isSubmitting ? 'Change' : !!isRedirecting ? 'Redirecting...' : 'Changing...'}
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default withRouter(ResetForm)
