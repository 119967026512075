import { map } from 'lodash'

export const States = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  DistrictOfColumbia: 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  NewHampshire: 'NH',
  NewJersey: 'NJ',
  NewMexico: 'NM',
  NewYork: 'NY',
  NorthCarolina: 'NC',
  NorthDakota: 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  RhodeIsland: 'RI',
  SouthCarolina: 'SC',
  SouthDakota: 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  WestVirginia: 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
  PuertoRico: 'PR',
}

export const TextStates = {
  [States.Alabama]: 'Alabama',
  [States.Alaska]: 'Alaska',
  [States.Arizona]: 'Arizona',
  [States.Arkansas]: 'Arkansas',
  [States.California]: 'California',
  [States.Colorado]: 'Colorado',
  [States.Connecticut]: 'Connecticut',
  [States.Delaware]: 'Delaware',
  [States.DistrictOfColumbia]: 'District Of Columbia',
  [States.Florida]: 'Florida',
  [States.Georgia]: 'Georgia',
  [States.Hawaii]: 'Hawaii',
  [States.Idaho]: 'Idaho',
  [States.Illinois]: 'Illinois',
  [States.Indiana]: 'Indiana',
  [States.Iowa]: 'Iowa',
  [States.Kansas]: 'Kansas',
  [States.Kentucky]: 'Kentucky',
  [States.Louisiana]: 'Louisiana',
  [States.Maine]: 'Maine',
  [States.Maryland]: 'Maryland',
  [States.Massachusetts]: 'Massachusetts',
  [States.Michigan]: 'Michigan',
  [States.Minnesota]: 'Minnesota',
  [States.Mississippi]: 'Mississippi',
  [States.Missouri]: 'Missouri',
  [States.Montana]: 'Montana',
  [States.Nebraska]: 'Nebraska',
  [States.Nevada]: 'Nevada',
  [States.NewHampshire]: 'New Hampshire',
  [States.NewJersey]: 'New Jersey',
  [States.NewMexico]: 'New Mexico',
  [States.NewYork]: 'New York',
  [States.NorthCarolina]: 'North Carolina',
  [States.NorthDakota]: 'North Dakota',
  [States.Ohio]: 'Ohio',
  [States.Oklahoma]: 'Oklahoma',
  [States.Oregon]: 'Oregon',
  [States.Pennsylvania]: 'Pennsylvania',
  [States.RhodeIsland]: 'Rhode Island',
  [States.SouthCarolina]: 'South Carolina',
  [States.SouthDakota]: 'South Dakota',
  [States.Tennessee]: 'Tennessee',
  [States.Texas]: 'Texas',
  [States.Utah]: 'Utah',
  [States.Vermont]: 'Vermont',
  [States.Virginia]: 'Virginia',
  [States.Washington]: 'Washington',
  [States.WestVirginia]: 'West Virginia',
  [States.Wisconsin]: 'Wisconsin',
  [States.Wyoming]: 'Wyoming',
  [States.PuertoRico]: 'Puerto Rico',
}

export const STATE_OPTIONS = map(States, (s) => ({
  value: s,
  label: TextStates[s],
}))
