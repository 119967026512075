import React from 'react'
import Icon from './Icon'

const Chevron = props => {
  return (
    <Icon className="fill" viewBox="0 0 12 12" {...props}>
      <path d="M9.707 5.293l-5-5a1 1 0 0 0-1.414 1.414L7.586 6l-4.293 4.293a1 1 0 1 0 1.414 1.414l5-5a1 1 0 0 0 0-1.414z" />
    </Icon>
  )
}

export default Chevron
