import React, { Component } from 'react'
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { protectedRequest } from '../../utils/requests/api'
import { MicIcon, MicOffIcon, VisibilityIcon, VisibilityOffIcon, LeaveIcon, Notes, TestTube } from '../icons'

import { DoctorNotes, MediaPermissions, WaitingRoom, LeftRoom } from '.'
import { ConfirmModal } from '../ui/modals'
import { toggleTestOrder } from '../../utils/hooks/usePatient'
import { getAppointmentTestOrder } from '../../utils/hooks/useAppointment'
import { TestOrderStatus } from '../../utils/constants/test-order'

class VideoContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      connection: 'Connecting',
      publishVideo: true,
      publishAudio: true,
      apiKey: '46727332',
      sessionId: undefined,
      token: undefined,
      notesOpen: false,
      orderTest: false,
      permissions: false,
      isHangupModalOpen: false,
      isOrderDisabled: true,
      patientId: undefined,
      waiting: true,
      isLeft: false,
    }

    this.sessionEventHandlers = {
      sessionConnected: () => {
        console.log('Connected')
        this.setState({ connection: 'Connected' })
      },
      sessionDisconnected: () => {
        console.log('Disconnected')
        this.setState({ connection: 'Disconnected' })
      },
      sessionReconnected: () => {
        console.log('Reconnected')
        this.setState({ connection: 'Reconnected' })
      },
      sessionReconnecting: () => {
        console.log('Reconnecting')
        this.setState({ connection: 'Reconnecting' })
      },
      streamDestroyed: (event) => {
        console.log('streamDestroyed', event.stream.id)
        this.setState({ isLeft: true })
      },
      streamCreated: (event) => {
        console.log('streamCreated', event.stream.id)
        this.setState({ isLeft: false })
      },
    }

    this.properties = {
      showControls: false,
    }

    this.publisherEventHandlers = {
      accessDenied: () => {
        console.log('User denied access to media source')
      },
      streamCreated: () => {
        console.log('Publisher stream created')
      },
      streamDestroyed: ({ reason }) => {
        console.log(`Publisher stream destroyed because: ${reason}`)
      },
    }

    this.subscriberEventHandlers = {
      videoEnabled: () => {
        console.log('Subscriber video enabled')
      },
      videoDisabled: () => {
        console.log('Subscriber video disabled')
      },
    }
  }

  interval = null

  joinAppointment = async () => {
    //todo: set max tries
    const { appointmentId } = this.props

    let path = 'token'
    let method = 'get'

    const { data } = await protectedRequest(method, `appointments/${appointmentId}/${path}`)
    if (data) {
      const { id, status, userId } = await getAppointmentTestOrder('', appointmentId)
      const { sessionId, token } = data
      this.setState({
        sessionId,
        token,
        testId: id,
        patientId: userId,
        isOrderDisabled: false,
        orderTest: status === TestOrderStatus.Created,
      })
    } else {
      this.joinAppointment()
    }
  }

  componentDidMount = async () => {
    this.joinAppointment()
  }

  onSessionError = (error) => {
    this.setState({ error })
  }

  onPublish = () => {
    console.log('Publish Success')
  }

  onPublishError = (error) => {
    if (error.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
      this.setState({
        permissions: false,
      })
    } else {
      this.setState({ error })
    }
  }

  onSubscribe = () => {
    console.log('Subscribe Success')
    this.setState({ waiting: false })
  }

  onSubscribeError = (error) => {
    this.setState({ error })
  }

  toggleNotes = () => {
    this.setState((state) => ({
      notesOpen: !state.notesOpen,
    }))
  }

  toggleTest = async () => {
    const { isOrderDisabled, testId, orderTest, patientId } = this.state

    if (!isOrderDisabled) {
      this.setState({ isOrderDisabled: true })

      try {
        await toggleTestOrder('', patientId, testId, orderTest ? TestOrderStatus.Removed : TestOrderStatus.Created)
        this.setState({ orderTest: !orderTest })
      } catch (err) {
        console.log(err)
      } finally {
        this.setState({ isOrderDisabled: false })
      }
    }
  }

  toggleAudio = () => {
    this.setState((state) => ({
      publishAudio: !state.publishAudio,
    }))
  }

  redirectLeave = async () => {
    const { testId } = this.state
    const { isDoctor, appointmentId, history } = this.props
    if (isDoctor) {
      return history.push(`/appointment/${appointmentId}/post`)
    }

    try {
      const { status } = await getAppointmentTestOrder('', appointmentId)

      if (status === TestOrderStatus.Created) {
        return history.push(`/appointment/${appointmentId}/order/test/${testId}`)
      }
      //redirect to dashboard
      return history.push(`/dashboard`)
    } catch (err) {
      console.log(err)
    }
  }

  toggleVideo = () => {
    this.setState((state) => ({
      publishVideo: !state.publishVideo,
    }))
  }

  toggleHangupModal = () => {
    this.setState((state) => ({
      isHangupModalOpen: !state.isHangupModalOpen,
    }))
  }

  endCallAction = () => {
    this.redirectLeave()
  }

  render() {
    const {
      error,
      publishAudio,
      publishVideo,
      apiKey,
      sessionId,
      token,
      notesOpen,
      orderTest,
      permissions,
      waiting,
      isLeft,
    } = this.state

    const { isDoctor, patientData } = this.props

    //todo: fix dimensions
    const VIDEO_DIMENSIONS = {
      mine: {
        width: '200px',
        height: '160px',
      },
      others: {
        width: '400px',
        height: '320px',
      },
    }

    return (
      <div className="VideoContainer">
        {/* Bring in connection prop */}
        {/* <div id="sessionStatus">Session Status: {connection}</div> */}
        {error ? (
          <div className="error">
            <strong>Error:</strong> {error.message}
          </div>
        ) : null}
        {!permissions && (
          <MediaPermissions
            permissions={permissions}
            setPermissions={(permissions) => this.setState({ permissions })}
          />
        )}

        {permissions && waiting && <WaitingRoom other={isDoctor ? 'patient' : 'doctor'} />}
        {permissions && isLeft && <LeftRoom other={isDoctor ? 'patient' : 'doctor'} />}
        {apiKey && sessionId && token && permissions && (
          <OTSession
            apiKey={apiKey}
            sessionId={sessionId}
            token={token}
            onError={this.onSessionError}
            eventHandlers={this.sessionEventHandlers}
          >
            <div className={classNames('videos-container', { 'notes-open': notesOpen })}>
              <OTPublisher
                properties={{ publishAudio, publishVideo, ...VIDEO_DIMENSIONS.mine, ...this.properties }}
                onPublish={this.onPublish}
                onError={this.onPublishError}
                eventHandlers={this.publisherEventHandlers}
                showControls={false}
              />
              <OTStreams>
                <OTSubscriber
                  properties={{ ...VIDEO_DIMENSIONS.others, ...this.properties }}
                  onSubscribe={this.onSubscribe}
                  onError={this.onSubscribeError}
                  eventHandlers={this.subscriberEventHandlers}
                />
              </OTStreams>

              <div className={classNames('video-bar', { open: notesOpen })}>
                <div className="video-controls">
                  {isDoctor && (
                    <div
                      className={classNames('video-control-button notes', { active: notesOpen })}
                      onClick={this.toggleNotes}
                    >
                      <Notes />
                    </div>
                  )}
                  {isDoctor && (
                    <div
                      className={classNames('video-control-button notes', { active: orderTest })}
                      onClick={() => this.toggleTest()}
                    >
                      <TestTube size={32} />
                    </div>
                  )}

                  <div
                    className={classNames('video-control-button', { off: !publishAudio })}
                    onClick={this.toggleAudio}
                  >
                    {publishAudio ? <MicIcon /> : <MicOffIcon />}
                  </div>
                  <div
                    onClick={this.toggleVideo}
                    className={classNames('video-control-button', { off: !publishVideo })}
                  >
                    {publishVideo ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </div>
                  <div className="video-control-button hang-up" onClick={this.toggleHangupModal}>
                    <LeaveIcon />
                  </div>
                </div>
                {notesOpen && isDoctor && (
                  <div className="notes-container">
                    <DoctorNotes patientData={patientData} canEdit={false} />
                  </div>
                )}
              </div>
            </div>
          </OTSession>
        )}
        <ConfirmModal
          isModalOpen={this.state.isHangupModalOpen}
          setIsModalOpen={this.toggleHangupModal}
          title="End Call"
          message="Are you sure you want to end the call?"
          type="danger"
          buttonText={'End Call'}
          buttonAction={this.endCallAction}
        />
      </div>
    )
  }
}

export default withRouter(connect((state) => ({ me: state.auth.me }))(VideoContainer))
