import React from 'react'
import { LoginForm } from '../../components/forms'
import { Logo } from '../../components/general'
import { PHILIPS_APP_URL } from '../../config'

const Login = () => {
  const isPhilips = window.location.hostname.includes('philips')
  const philipsAppUrl = PHILIPS_APP_URL

  return (
    <div className="View Login">
      <div className="section tight">
        <Logo />
      </div>
      <LoginForm isPhilips={isPhilips} philipsAppUrl={philipsAppUrl} />
    </div>
  )
}

export default Login
