import React from 'react'
import { SignUpForm } from '../../components/forms'
import { Logo } from '../../components/general'
import { PHILIPS_APP_URL } from '../../config'

const SignUp = () => {
  const isPhilips = window.location.hostname.includes('philips')
  const philipsAppUrl = PHILIPS_APP_URL
  return (
    <div className="View SignUp">
      <div className="section tight">
        <Logo />
      </div>
      <SignUpForm isPhilips={isPhilips} philipsAppUrl={philipsAppUrl} />
    </div>
  )
}

export default SignUp
