import React from 'react'
import Icon from './Icon'

const Breath = (props) => (
  <Icon viewBox="0 0 32 32" className="stroke fill-none" {...props}>
    <path
      d="M19 0v2l3.87 5.419A1 1 0 0 1 22.058 9H18l2.128 2.838a1 1 0 0 1-.525 1.561l-3.131.895c-.877.25-.988 1.448-.173 1.856l2.807 1.403a1 1 0 0 1 .447 1.341L18 22c.167 2-.4 5-4 5h-2v5"
      className="stroke tertiary"
      strokeWidth={2}
    />
    <path d="M7 10a3 3 0 1 1-6 0c0-3 3-6 3-6s3 3 3 6z" className="stroke primary" strokeWidth={2} />
    <path
      d="M24 20c2.819 0 4.742.89 6 2M24 12c2.819 0 4.742-.89 6-2M24 16h8"
      className="stroke secondary"
      strokeWidth={2}
    />
  </Icon>
)

export default Breath
