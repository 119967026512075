import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { Chevron } from '../icons'

const Card = ({ className, to, showArrow, children, onClick }) => {
  //TODO: Handle when no to link prop
  let history = useHistory()

  return (
    <div className={classNames('Card', className)} onClick={() => (to ? history.push(to) : onClick?.())}>
      {children}
      <div className="direction-icon">{showArrow && <Chevron />}</div>
    </div>
  )
}

export default Card

Card.defaultProps = {
  showArrow: true,
}
