import React from 'react'
import { useSelector } from 'react-redux'
import { withAppointment } from './AppointmentProvider'
import { VideoContainer } from '../../../../components/appointments/'
import { useHistory } from 'react-router-dom'

const Appointment = ({ appointmentId, patientData }) => {
  const { role } = useSelector((state) => state.auth.me)
  const history = useHistory()
  const isPatient = role === 'PATIENT'

  return (
    <div className="Appointment View cover">
      <VideoContainer appointmentId={appointmentId} history={history} isDoctor={!isPatient} patientData={patientData} />
    </div>
  )
}

export default withAppointment(Appointment)
