import React from 'react'
import { IntakeWizard } from '../../../components/intake'

const PatientIntake = () => {
  return (
    <div className="PatientIntake View">
      <IntakeWizard />
    </div>
  )
}

export default PatientIntake
