import React from 'react'
import classNames from 'classnames'

const LoadingSpinner = ({ className }) => {
  return (
    <div className={classNames('LoadingSpinner loading-spinner-container', className)}>
      <div className="loading-spinner spin" />
    </div>
  )
}

export default LoadingSpinner
