import React from 'react'
import { get, isEmpty } from 'lodash'
import { useAppointments } from '../../utils/hooks/useAppointments'
import { AppointmentCard } from '.'
import { LoadingSpinner, FallbackCard } from '../ui'

//TODO: Move to constants
// const inValidStatuses = ['unpaid', 'removed']

const AppointmentList = ({ patientId }) => {
  const { status, data, error } = useAppointments(patientId)

  if (status === 'loading') {
    return <LoadingSpinner />
  }

  if (status === 'error') {
    return <div>Error: {error.message}</div>
  }

  const appointments = get(data, 'data', [])

  console.log('appointments', appointments)
  // const appointmentsToShow = appointments.filter(({ paymentStatus }) => !inValidStatuses.includes(paymentStatus))
  const appointmentsToShow = appointments

  const fallBackMessage = patientId
    ? 'Patient has not scheduled any appointments yet'
    : 'You do not have any scheduled appointments yet...'
  const to = patientId ? '/patients' : '/'
  const toText = patientId ? 'Back to my patients' : 'Back to dashboard'

  return (
    <div className="Appointments">
      {!isEmpty(appointments) ? (
        appointmentsToShow.map((appointment) => <AppointmentCard key={appointment.id} appointment={appointment} />)
      ) : (
        <FallbackCard message={fallBackMessage} to={to} toText={toText} />
      )}
    </div>
  )
}

export default AppointmentList
