import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Landing, Login, SignUp, Reset, Forgot, Unsupported } from './'

const UnprotectedApp = ({ history }) => {
  return (
    <Switch>
      <Route exact path="/unsupported" component={Unsupported} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/forgot" component={Forgot} />
      <Route exact path="/reset-password" component={Reset} />
      <Route exact path="/landing" component={Landing} />
      <Redirect
        from="/"
        to={{
          pathname: '/login',
          search:
            history.location.pathname !== '/settings' &&
            history.location.pathname !== '/' &&
            `?next=${history.location.pathname}` + encodeURIComponent(history.location.search),
        }}
      />
    </Switch>
  )
}

export default withRouter(UnprotectedApp)
