import React from 'react'
import classNames from 'classnames'

const Icon = ({ size, viewBox, fill, stroke, className, ...rest }) => {
  let style = {}
  // if (fill) {
  //   style.fill = 'currentcolor'
  // }
  // if (stroke) {
  //   style.stroke = 'currentcolor'
  // }
  return (
    <svg
      className={classNames('Icon', className)}
      style={{ width: `${size}px`, height: `${size}px`, ...style }}
      viewBox={viewBox}
      {...rest}
    />
  )
}

Icon.defaultProps = {
  viewBox: '0 0 32 32',
}

export default Icon
