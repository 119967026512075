import React from 'react'
import { Logo } from '../../components/general'
import { FTP_URL } from '../../config'

const openSafari = () => {
  document.location.href = `${FTP_URL}?url=${encodeURIComponent(document.location.href)}`
}

const Unsupported = () => {
  setTimeout(openSafari, 2000)
  return (
    <div className="View Unsupported">
      <div className="section">
        <Logo />
      </div>
      <div>StreamMD requires Safari on iOS!</div>
      <div>Opening Safari!</div>
    </div>
  )
}

export default Unsupported
