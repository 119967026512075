import React from 'react'
import './styles/index.css'
import { ReactQueryDevtools } from 'react-query-devtools'

// REDUX
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'

import reducer from './resources'
import ConnectedApp from './ConnectedApp'

const middleware = applyMiddleware(thunk)

const store = createStore(
  reducer,
  compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : compose)
)

function App() {
  return (
    <Provider store={store}>
      <ConnectedApp />
      <ReactQueryDevtools initialIsOpen={false} />
    </Provider>
  )
}

export default App
