import React from 'react'
const WaitingRoom = ({ other }) => {
  console.log('waiting room')
  return (
    <div className="WaitingRoom View section">
      <div className="section-title">Your {other} will join shortly.</div>
    </div>
  )
}
export default WaitingRoom
