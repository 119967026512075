import React from 'react'
import Icon from './Icon'

const LogoSymbol = (props) => (
  <Icon viewBox="0 0 150 150" {...props}>
    <path d="M50 0h50v50h50v50h-50v50H50v-50H0V50h50V0z" className="fill primary" />
    <path d="M107 0h-3v6h3a36.998 36.998 0 0 1 37 37v3h6v-3a43 43 0 0 0-43-43z" className="fill secondary" />
    <path
      d="M104 12h3a30.999 30.999 0 0 1 31 31v3h-6v-3a25.007 25.007 0 0 0-7.322-17.678A25.01 25.01 0 0 0 107 18h-3v-6z"
      className="fill secondary"
    />
    <path
      d="M107 24h-3v6h3a13 13 0 0 1 13 13v3h6v-3a18.997 18.997 0 0 0-19-19zM108.5 46a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9z"
      className="fill secondary"
    />
  </Icon>
)

export default LogoSymbol
