import React from 'react'
import { Back } from '../../../components/general'
import { Result as ResultContent } from '../../../components/results'

const Result = () => {
  return (
    <div className="View Result">
      <div className="section header">
        <Back />
      </div>
      <ResultContent />
    </div>
  )
}

export default Result
