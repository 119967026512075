import React from 'react'
import Icon from './Icon'

const MusclePain = (props) => (
  <Icon viewBox="0 0 32 32" className="fill-none" {...props}>
    <path
      d="M21 19v1a1 1 0 0 0 1-1h-1zm-2 0v-1a1 1 0 0 0-1 1h1zm-8 0h-1a1 1 0 0 0 1 1v-1zm2 0h1a1 1 0 0 0-1-1v1zm3-11a4 4 0 0 0 4-4h-2a2 2 0 0 1-2 2v2zm-4-4a4 4 0 0 0 4 4V6a2 2 0 0 1-2-2h-2zm4-4a4 4 0 0 0-4 4h2a2 2 0 0 1 2-2V0zm4 4a4 4 0 0 0-4-4v2a2 2 0 0 1 2 2h2zm1 14h-2v2h2v-2zm-1-8v9h2v-9h-2zm-2-2a2 2 0 0 1 2 2h2a4 4 0 0 0-4-4v2zm-2 0h2V6h-2v2zm-2 0h2V6h-2v2zm-2 2a2 2 0 0 1 2-2V6a4 4 0 0 0-4 4h2zm0 9v-9h-2v9h2zm1-1h-2v2h2v-2zm1 11V19h-2v10h2zm1 1a1 1 0 0 1-1-1h-2a3 3 0 0 0 3 3v-2zm2 0h-2v2h2v-2zm1-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2zm0-10v10h2V19h-2z"
      className="fill primary"
    />
    <path d="M24 13l3-3M8 13l-3-3M24 17l3 3M8 17l-3 3M26 15h4M6 15H2" className="stroke tertiary" strokeWidth={2} />
  </Icon>
)

export default MusclePain
