import React, { useState } from 'react'
import { Card } from '../../../components/ui'
import Select from 'react-select';
import classNames from 'classnames'
import Modal from '../../../components/ui/modals/Modal';

const ResultCard = ({ result, testOrders, assignTestResult }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState()

  const onComplete = () => {
    if (!selectedOption) return;
    const cb = () => setIsModalOpen(false)
    assignTestResult({ id: result.id, testOrderId: selectedOption.value }, cb)
  }

  return (
    <>
      <Card className="ResultCard" onClick={() => setIsModalOpen(true)}>
        <div className="name">
          Result No. {result.chronoId}
        </div>
      </Card>
      <Modal
        className="review-modal"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={`Result: ${result.chronoId}`}
        body={
          <>
            <label className="select-label">Select a test order</label>
            <Select
              className="select-field"
              classNamePrefix="select"
              value={selectedOption}
              options={testOrders}
              onChange={setSelectedOption}
              isClearable
              isSearchable
            />
            <iframe title="IFrameContainer" src={`${result.url}&#toolbar=0&navpanes=0&view=Fit`} width="100%" height="640px" />
          </>
        }
        footer={
          <div className={classNames("button short", { inactive: !selectedOption })} onClick={onComplete}>
            Assign result to order
          </div>
        }
      />
    </>
  )
}

export default ResultCard
