import React from 'react'
import Icon from './Icon'

const Thermometer = (props) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M12 18.255l.572.82.428-.299v-.521h-1zm8 0h-1v.521l.428.299.572-.82zM13 5a3 3 0 0 1 3-3V0a5 5 0 0 0-5 5h2zm0 13.255V5h-2v13.255h2zM10 24a5.992 5.992 0 0 1 2.572-4.925l-1.144-1.64A7.992 7.992 0 0 0 8 24h2zm6 6a6 6 0 0 1-6-6H8a8 8 0 0 0 8 8v-2zm6-6a6 6 0 0 1-6 6v2a8 8 0 0 0 8-8h-2zm-2.572-4.925A5.992 5.992 0 0 1 22 24h2a7.992 7.992 0 0 0-3.428-6.566l-1.144 1.64zM19 5v13.255h2V5h-2zm-3-3a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5v2z"
      className="fill primary"
    />
    <path d="M16 6v14" className="stroke tertiary" strokeWidth={2} strokeLinecap="round" />
    <path d="M23 3h5M23 7h6M23 11h4" className="stroke secondary" strokeWidth={2} />
    <circle cx={16} cy={24} r={4} className="fill tertiary" />
  </Icon>
)

export default Thermometer
