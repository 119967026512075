import React from 'react'
import { get } from 'lodash'
import classNames from 'classnames'
import { connect as formikConnect, getIn } from 'formik'

const Choices = ({
  name,
  component,
  choices,
  className,
  shortLabel,
  readOnly,
  readOnlyCondition,
  isRequired,
  formik: { setFieldValue, setFieldTouched, values },
}) => {
  const isChecked = (value) => {
    const currentValues = get(values, name, '')
    if (component === 'checkbox') {
      return currentValues.includes(value)
    } else {
      return currentValues === value
    }
  }

  const onChange = (evt) => {
    const currentValue = values[name] || []
    let newValue
    if (evt.target.type === 'checkbox') {
      if (currentValue.includes(evt.target.value)) {
        newValue = currentValue.filter((v) => v !== evt.target.value)
      } else {
        newValue = [...currentValue, evt.target.value]
      }
    } else {
      newValue = evt.target.value
    }
    setFieldTouched(name)
    setFieldValue(name, newValue)
  }

  const isInline = className && className.includes('inline')
  const currentValue = getIn(values, name)

  return (
    <div className="Choices">
      {readOnly && <div className="label">{shortLabel}</div>}
      {choices.map((choice, index) => {
        const Icon = choice.icon
        const shouldShowChoice =
          currentValue &&
          (currentValue === choice.value || (Array.isArray(currentValue) && currentValue.includes(choice.value)))
        return (
          (shouldShowChoice || !readOnly) && (
            <label key={choice.value} className="ChoiceInput">
              {isInline && !readOnly && <div className="label-container">{choice.label}</div>}
              {choice.label && (
                <div className={classNames('read-only-label', { highlight: choice.label.toLowerCase() === 'yes' })}>
                  {choice.label}
                </div>
              )}
              <input
                name={name}
                type={component}
                value={choice.value}
                onChange={(evt) => onChange(evt)}
                checked={isChecked(choice.value)}
              />
              <div className="pseudo-input-container">
                {Icon && (
                  <div className="icon-container">
                    <Icon />
                  </div>
                )}
                {!isInline && <div className="label-container">{choice.label}</div>}
              </div>
            </label>
          )
        )
      })}
    </div>
  )
}

export default formikConnect(Choices)
