import React from 'react'
import classNames from 'classnames'
import { Modal } from './'

const THEMES = {
  danger: 'danger',
}

const ConfirmModal = ({ isModalOpen, setIsModalOpen, title, message, type, buttonText, buttonAction, ...rest }) => {
  // const [isModalOpen, setIsModalOpen] = useState(false)

  const Body = <div className="confirm-message">{message}</div>

  const Footer = (
    <>
      <button className={classNames('button small', THEMES[type])} onClick={buttonAction}>
        {buttonText}
      </button>
    </>
  )
  return (
    <Modal
      className="confirm-modal"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      title={title}
      body={Body}
      footer={Footer}
      {...rest}
    />
  )
}

export default ConfirmModal
