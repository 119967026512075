import React from 'react'
import Icon from './Icon'

const NoContact = (props) => (
  <Icon viewBox="0 0 32 32" className="fill-none" {...props}>
    <path
      d="M16 1v2M16 5v2M16 9v2M16 13v2M16 17v2M16 21v2M16 25v2M16 29v2"
      className="stroke secondary"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M12 19v1a1 1 0 0 0 1-1h-1zm-2 0v-1a1 1 0 0 0-1 1h1zm-8 0H1a1 1 0 0 0 1 1v-1zm2 0h1a1 1 0 0 0-1-1v1zM7 8a4 4 0 0 0 4-4H9a2 2 0 0 1-2 2v2zM3 4a4 4 0 0 0 4 4V6a2 2 0 0 1-2-2H3zm4-4a4 4 0 0 0-4 4h2a2 2 0 0 1 2-2V0zm4 4a4 4 0 0 0-4-4v2a2 2 0 0 1 2 2h2zm1 14h-2v2h2v-2zm-1-8v9h2v-9h-2zM9 8a2 2 0 0 1 2 2h2a4 4 0 0 0-4-4v2zM7 8h2V6H7v2zM5 8h2V6H5v2zm-2 2a2 2 0 0 1 2-2V6a4 4 0 0 0-4 4h2zm0 9v-9H1v9h2zm1-1H2v2h2v-2zm1 11V19H3v10h2zm1 1a1 1 0 0 1-1-1H3a3 3 0 0 0 3 3v-2zm2 0H6v2h2v-2zm1-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3H9zm0-10v10h2V19H9zM30 19v1a1 1 0 0 0 1-1h-1zm-2 0v-1a1 1 0 0 0-1 1h1zm-8 0h-1a1 1 0 0 0 1 1v-1zm2 0h1a1 1 0 0 0-1-1v1zm3-11a4 4 0 0 0 4-4h-2a2 2 0 0 1-2 2v2zm-4-4a4 4 0 0 0 4 4V6a2 2 0 0 1-2-2h-2zm4-4a4 4 0 0 0-4 4h2a2 2 0 0 1 2-2V0zm4 4a4 4 0 0 0-4-4v2a2 2 0 0 1 2 2h2zm1 14h-2v2h2v-2zm-1-8v9h2v-9h-2zm-2-2a2 2 0 0 1 2 2h2a4 4 0 0 0-4-4v2zm-2 0h2V6h-2v2zm-2 0h2V6h-2v2zm-2 2a2 2 0 0 1 2-2V6a4 4 0 0 0-4 4h2zm0 9v-9h-2v9h2zm1-1h-2v2h2v-2zm1 11V19h-2v10h2zm1 1a1 1 0 0 1-1-1h-2a3 3 0 0 0 3 3v-2zm2 0h-2v2h2v-2zm1-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2zm0-10v10h2V19h-2z"
      className="fill primary"
    />
  </Icon>
)

export default NoContact
