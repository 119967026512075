import React from 'react'
import Select from 'react-select'
import { find } from 'lodash'
import { connect as formikConnect, getIn } from 'formik'

const SelectList = ({ options, name, placeholder, formik: { values, setFieldValue, setFieldTouched } }) => {
  const value = getIn(values, name)
  return (
    <Select
      classNamePrefix="select"
      className="Select"
      placeholder={placeholder}
      options={options}
      value={find(options, (option) => option.value === value)}
      onChange={(option) => {
        setFieldTouched(name)
        setFieldValue(name, option.value)
      }}
    />
  )
}

export default formikConnect(SelectList)
