import React, { useEffect, useState } from 'react'
import PaymentForm from '../../../../components/square/paymentForm'
import { createSquarePayment, getSquarePaymentsParams } from '../../../../utils/hooks/useSquare'
import { StringParam, useQueryParam } from 'use-query-params'
import { addAppointmentPatientData, getAppointmentById } from '../../../../utils/hooks/useAppointment'

const checkAppointmentPaymentStatus = (appointmentId, resolve, reject) => {
  setTimeout(() => {
    getAppointmentById('', appointmentId)
      .then((response) => {
        if (response.data.paymentStatus === 'paid') {
          console.log('Resolve')
          return resolve()
        }
        return checkAppointmentPaymentStatus(appointmentId, resolve, reject)
      })
      .catch(reject)
  }, 1000)
}

const createCheckAppointmentPaymentStatus = (appointmentId) => {
  return new Promise((resolve, reject) => {
    checkAppointmentPaymentStatus(appointmentId, resolve, reject)
  })
}

const submitPayment = (history, appointmentId, setIsSubmitting, setMessage, setErrors) => async (form) => {
  setIsSubmitting(true)
  await createSquarePayment(form.nonce, appointmentId)
    .then((response) => {
      if (response.paymentStatus === 'paid') {
        return Promise.resolve()
      }
      setMessage('Loading payment status...')
      return createCheckAppointmentPaymentStatus(appointmentId)
    })
    .then(() => {
      setMessage('Submitting Patient Data...')
      const notes = JSON.parse(localStorage.getItem('intake'))
      return addAppointmentPatientData(appointmentId, notes)
    })
    .then((response) =>
      setTimeout(() => {
        setMessage('Redirecting To Dashboard...')
        history.push('/dashboard')
      }, 2000)
    )
    .catch((error) => {
      console.log(error)
      setIsSubmitting(false)

      if (error?.response?.data?.error?.errors) {
        setErrors(error.response.data.error.errors)
      } else {
        setMessage(error?.response?.data?.error || error?.request?.statusText || 'Error Message')
      }
    })
}
const handleComponentMount = (setLoaded, setMessage, setAmount, setAppId) => () => {
  //todo: add getting of params => domain, appId
  getSquarePaymentsParams()
    .then(({ squarePaymentLibPath, clientId, appointmentPrice }) => {
      setMessage('')
      setAppId(clientId)
      setAmount(appointmentPrice)

      let sqPaymentScript = document.createElement('script')
      sqPaymentScript.src = squarePaymentLibPath
      sqPaymentScript.type = 'text/javascript'
      sqPaymentScript.async = false
      sqPaymentScript.onload = () => {
        setLoaded(true)
      }
      document.getElementsByTagName('head')[0].appendChild(sqPaymentScript)
    })
    .catch((error) => {
      setMessage(error?.response?.data?.error || error?.request?.statusText || 'Error Message')
    })
}

const AppointmentCheckout = ({ history }) => {
  const [loaded, setLoaded] = useState()
  const [message, setMessage] = useState()
  const [errors, setErrors] = useState()
  const [appId, setAppId] = useState()
  const [amount, setAmount] = useState()
  const [isSubmitting, setIsSubmitting] = useState()
  const [appointmentId] = useQueryParam('appointmentId', StringParam)

  useEffect(handleComponentMount(setLoaded, setMessage, setAmount, setAppId), [])

  const isReady = appId && loaded
  return (
    <div className="View relative">
      <PaymentForm
        amount={amount}
        appId={appId}
        isLoaded={isReady}
        message={message}
        errors={errors}
        paymentForm={window.SqPaymentForm}
        isSubmitting={isSubmitting}
        onSubmit={submitPayment(history, appointmentId, setIsSubmitting, setMessage, setErrors)}
      />
    </div>
  )
}

export default AppointmentCheckout
