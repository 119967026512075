import React from 'react'
import Icon from './Icon'

const Fatigue = (props) => (
  <Icon viewBox="0 0 32 32" className="stroke fill-none" {...props}>
    <path
      d="M1 29c3.42-4.858 8.867-8 15-8 6.134 0 11.58 3.142 15 8"
      className="stroke tertiary"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path d="M10.5 4.876A9 9 0 1 1 7.223 14" className="stroke primary" strokeWidth={2} strokeLinejoin="round" />
    <path
      d="M14 12a2 2 0 1 1-4 0M22 12a2 2 0 1 1-4 0M8 8.667C8 10.507 6.433 12 4.5 12S1 10.508 1 8.667C1 5.333 4.5 2 4.5 2S8 5.333 8 8.667z"
      className="stroke secondary"
      strokeWidth={2}
    />
  </Icon>
)

export default Fatigue
