import React, { useState } from 'react'
import { Modal } from './'

const LinkModal = ({ link, ...rest }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div className="LinkModal">
      <div className="link small" onClick={() => setIsModalOpen(true)}>
        {link}
      </div>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} {...rest} />
    </div>
  )
}

export default LinkModal
