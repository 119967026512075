import React from 'react'
import { useSelector } from 'react-redux'
import { DoctorDashboard } from './doctor/'
import { PatientDashboard } from './patient/'
import { AdminDashboard } from './admin'

const Dashboard = () => {
  const { role } = useSelector(state => state.auth.me)

  let UserDashboard;
  switch (role) {
    case 'PROVIDER':
      UserDashboard = DoctorDashboard
      break;
    case 'ADMIN':
      UserDashboard = AdminDashboard
      break;
    default:
      UserDashboard = PatientDashboard
  }

  return <UserDashboard />
}

export default Dashboard
