import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { isNull } from 'lodash'
import { ProtectedApp, UnprotectedApp } from './views'
import { validateTokenAction } from './resources'
import { ScrollToTop } from './components/general'
import { QueryParamProvider } from 'use-query-params'
import ErrorBoundary from './components/boundary/ErrorBoundary'
import { LoadingSpinner } from './components/ui'
import Analytics from './components/Analytics'
import { Unsupported } from './views/unprotected'

const isUnsupported = () =>
  /iPad|iPhone/.test(navigator.userAgent) &&
  !window.MSStream &&
  navigator.userAgent.toLowerCase().indexOf('crios/') > -1

const ConnectedApp = () => {
  const dispatch = useDispatch()
  const validateToken = (hasToken) => dispatch(validateTokenAction(hasToken))
  const { hasValidatedToken, isLoggedIn } = useSelector((state) => state.auth)

  useEffect(() => {
    let token = localStorage.getItem('token')
    validateToken(!isNull(token))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="App ConnectedApp">
      <ErrorBoundary>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            {isUnsupported() ? (
              <Unsupported />
            ) : (
              <>
                <Analytics />
                <ScrollToTop />
                {!hasValidatedToken ? (
                  <LoadingSpinner />
                ) : isLoggedIn ? (
                  <Route component={ProtectedApp} />
                ) : (
                  <Route component={UnprotectedApp} />
                )}
              </>
            )}
          </QueryParamProvider>
        </Router>
      </ErrorBoundary>
    </div>
  )
}

export default ConnectedApp
