import React from 'react'
import { Back } from '../../../components/general'
import { PatientList } from '../../../components/patients'

const Patients = () => {
  return (
    <div className="View Patients">
      <div className="section header">
        <Back to="/" />
      </div>
      <div className="section">
        <div className="section-title">My Patients</div>
        <PatientList />
      </div>
    </div>
  )
}

export default Patients
