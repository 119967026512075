import React from 'react'

const Emergency = () => {
  return (
    <div className="Emergency View">
      <section className="section header">
        <div className="section-title">Emergency Room</div>
      </section>
      <section className="section stretch">
        <p className="message">
          Your symptoms indicate you should be evaluated in person by a medical provider.
          <br />
          <br />
          Please contact your local ER or primary care provider prior to arrival to alert them and determine their
          instructions.
        </p>
      </section>
      <section className="section fixed bottom">
        <a
          className="button"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.com/search?q=emergency+room+near+me"
        >
          Find an ER near you.
        </a>
      </section>
    </div>
  )
}

export default Emergency
