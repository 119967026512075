import React from 'react'
import Icon from './Icon'

const Nausea = (props) => (
  <Icon viewBox="0 0 32 32" className="fill-none" {...props}>
    <path d="M14 8h13a4 4 0 0 1 0 8H7M7 16h16a4 4 0 0 1 0 8h-7" className="stroke primary" strokeWidth={2} />
    <path
      d="M25 12H5a4 4 0 0 0 0 8h15M18 12h-4a4 4 0 0 1-4-4V3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v4M12 31v-7a4 4 0 0 1 4-4h5m-1 11v-6"
      className="stroke primary"
      strokeWidth={2}
    />
    <path d="M14 0v5" className="stroke secondary" strokeWidth={2} />
    <path d="M16 27v5M20 27v5M12 27v5" className="stroke tertiary" strokeWidth={2} />
  </Icon>
)

export default Nausea
