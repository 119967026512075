import React from 'react'

export const CONSENT_AGREEMENT = {
  title: 'StreamMD Consent & Policies',
  body: [
    <h4 className="policy-header">Consents</h4>,
    <p>
      By signing this form, I voluntarily understand, acknowledge, and agree to the following with respect to medical
      services rendered by StreamMD CA P.C., a California medical corporation, and their physicians (each a
      “Physician”), associates, technical assistants, agents, and other healthcare providers (collectively, “the
      Practice”).
    </p>,
    <p>
      I voluntarily consent to and authorize the services and treatment described herein, including without limitation
      telemedicine consultation, family medicine services and treatments, and internal medicine services and treatments,
      as well as those additional services that are advisable in the Practice’s professional judgment that may be
      rendered.
    </p>,
    <p>I understand that no promises have been made to me about the results of any treatments or services.</p>,
    <p>
      I understand that I have read and understood each of the provisions appearing on this consent form. I also
      acknowledge that I have had the opportunity to ask any questions that I may have, and by my electronic agreement,
      I consent and agree to all provisions herein both individually and collectively.
    </p>,
    <p>
      The consent will remain fully effective until it is revoked in writing. I have the right at any time to
      discontinue services.
    </p>,
    <h5 className="bold underline">Not Primary Care; Limit of Role</h5>,
    <p>
      The Practice is acting in a specialist, supportive, consultative capacity and not as a primary care physician
      (PCP) and does not provide emergency care. Accordingly, The Practice is not replacing care currently provided to
      me by other physicians, such as my current primary care physician, internist, gynecologist, cardiologist,
      gastroenterologist, pediatrician (in the case of children), or other specialty care. The Practice has advised me
      that the Practice does not admit patients to the hospital or treat hospitalized patients, and that I should
      maintain a relationship with a physician who is available to provide emergent and urgent care. The Practice does
      not provide immediate on-call services.
    </p>,
    <p>
      <span className="bold underline">
        IF I ENCOUNTER A MEDICAL EMERGENCY AND AM NOT ABLE TO OBTAIN CARE FROM MY PRIMARY CARE PHYSICIAN, I WILL CONTACT
        911 OR REPORT TO A HOSPITAL EMERGENCY DEPARTMENT.
      </span>{' '}
      If at any time I feel my condition has progressed, worsened, or has not improved over a long period of time, I
      agree to go to the nearest emergency room immediately.
    </p>,
    <p className="bold underline">
      I UNDERSTAND THAT THE PRACTICE IS CASH PAY ONLY AND DOES NOT SUBMIT CLAIMS OR BILLS FOR REIMBURSEMENT BY INSURANCE
      OR MEDICARE.
    </p>,
    <p className="bold underline">I ACKNOWLEDGE AND AGREE THAT THE SERVICES MAY NOT BE COVERED BY MEDICARE.</p>,
    <p>
      <span className="bold underline">
        Telemedicine Consent for Covid-19 & Internal Medicine (including Family Medicine):
      </span>
      &nbsp;I understand that the Practice may use telemedicine mechanisms to consult for, discuss, and recommend
      treatment for Covid-19 and internal medicine matters (including family medicine). I also understand that
      telemedicine involves the delivery of clinical health care services by electronic communication (including two-way
      audio-visual communication), as defined by applicable law. Use of telemedicine services by the Practice can result
      in benefits such as improved access to care and decreased exposure to community spread and person to person spread
      of illnesses. Potential risks include gaps of failures in communication, complicating healthcare decision-making,
      notwithstanding reasonable efforts to ensure the quality and reliability of transmitted information. There may be
      limitations to image quality or other electronic problems that are beyond the control of the healthcare provider.
      Despite reasonable security measures, online communications can be forwarded, intercepted, or even changed or
      falsified without my knowledge. I understand that the laws that protect privacy and the confidentiality of medical
      information also apply to telemedicine. I understand and agree to participate in telemedicine services.
    </p>,
    <p>
      <span className="underline">Pharmacy Consent:</span>
      &nbsp;I give the Practice authorization to obtain my prescription records from my participating pharmacies.
    </p>,

    <p>
      <span className="underline">Email, Phone, & Text Consent:</span>
      &nbsp;If at any time I provide an email or telephone number(s) at which I may be contacted, I consent to receiving
      appointment reminders and other healthcare communications/information at that email and/or telephone number(s)
      from the Practice via email, text message, and/or voicemail message.
    </p>,
    <p>
      <span className="underline">Duty to Inform:</span>
      &nbsp;I also understand it is my responsibility to keep the Practice informed of the name and contact information
      of my primary care physician and treating specialists, of any diagnoses I have received and of any treatments I
      have had or am now undergoing for current conditions. I also understand it is important for me to let my primary
      care physician know about any recommendations and treatments performed by the Practice, to ensure that my care is
      properly coordinated.
    </p>,
    <p>
      <span className="underline">Physical Exam Acknowledgment:</span>
      &nbsp;I have had an annual physical exam with my primary care physician of record.
    </p>,

    <p>
      <span className="underline">Alternatives Approaches:</span>
      &nbsp; As alternative approaches to my healthcare, the Practice encourages me to speak with and consider the
      advice of other physicians or appropriate healthcare practitioners regarding my overall care.
    </p>,
    <p>
      <span className="underline">Miscellaneous</span>
    </p>,

    <p>
      <span className="underline">My Participation:</span>
      &nbsp; I understand that I am responsible to disclose to the Practice all medication, care, treatment, diagnoses,
      and assessments that I receive elsewhere and am responsible to provide medical records from other providers to
      ensure that care is coordinated and compatible. Medical records can only be released with my authorization. I will
      need to obtain any records and/or labs that I would like the Practice to review.
    </p>,

    <p>
      <span className="underline">Off-Label Use:</span>
      &nbsp; I understand that the Practice may prescribe medications for uses other than those indicated by the drug
      manufacturer and approved by the federal Food and Drug Administration (off-label use). In such case, no one can be
      fully aware of all possible side effects and complications. The details of such off-label use including expected
      benefits, material risks and alternatives, have been explained to me in terms I understand. I have informed the
      Practice of all known allergies, and of all medications I am currently taking.
    </p>,

    <p>
      <span className="underline">No Claims or Guarantees:</span>
      &nbsp; I understand that the Practice makes no representations, claims or guarantees that my medical problems or
      conditions will be cured, solved, or helped by undergoing treatment by the Practice.
    </p>,

    <p>
      <span className="underline">Referrals:</span>
      &nbsp; I understand that the Practice’s treatment may include recommendation that I seek other types of treatment
      from other health professionals who are not affiliated with the Practice. I understand that the Practice does not
      supervise these professionals and is not clinically or legally responsible for them. I understand that they are
      not the Practice’s employees and that they will bill separately for their services.
    </p>,
    <p>
      <span className="underline">Assumption of Risk; Indemnity:</span>
      &nbsp; I choose to receive care that may involve clinical innovation and/or may differ from conventional medicine.
      Accordingly, I knowingly, voluntarily, and intelligently assume all risks involved in the same.
    </p>,
    <p>
      As a result of my assumption of these risks, I agree to release, hold harmless, indemnify, and defend the Practice
      from and against any and all claims which I (or my representatives) may have for any loss, damage, or injury
      arising out of or in connection with use of the treatments or services described herein, or arising out of or in
      connection with referral to other practitioners or merchants for delivery of any treatments or services. Further,
      I agree not to pursue a frivolous claim against the Practice, merely because I am dissatisfied with the results of
      the above treatments or services.
    </p>,
    <h4 className="policy-header">POLICIES</h4>,
    <p>
      By signing this form, I acknowledge that, with respect to services rendered by the Practice, I understand the
      following:
    </p>,
    <p>
      <span className="underline">Cancellation Fee:</span>
      &nbsp;You will only be charged after you choose to consult with a doctor and your appointment time and payment
      information are confirmed. You can cancel your appointment and receive a full refund, provided that you cancel at
      least 24 hours before your appointment time. If you miss your scheduled appointment, or cancel less than 24 hours
      before your appointment time, the Practice will not issue a refund. You hereby authorize this charge by providing
      the information below.
    </p>,
    <p>
      <span className="underline">No Refunds Post Consult:</span>
      &nbsp;The Practice does not offer any refund of any kind for consults after the consult has been performed.
    </p>,
    <p>
      <span className="underline">Electronic Signatures:</span>
      &nbsp;I agree that electronic signatures below are the legal equivalent of manual signatures on this Agreement,
      and manifest consent to be legally bound by this Agreement’s terms and conditions.
    </p>,
    <p>
      <span className="underline">Digital Records:</span>
      &nbsp;Provider may store medical and office records digitally. While the Practice will make reasonable efforts to
      keep the data secure according to legal requirements, and maintains the privacy and confidentiality of patient
      data, I understand that no system is 100% secure.
    </p>,
    <p>
      <span className="underline">Office Insurance Practices and Patient Financial Responsibility</span>
    </p>,
    <p>
      <span className="underline">Payment:</span>
      &nbsp;The Practice accepts credit cards only and is exclusively cash pay.
    </p>,
    <p>
      <span className="underline">No Participation in Insurance Plans:</span>
      &nbsp;The Practice is an out-of-network provider for services within this practice;&nbsp;
      <span className="underline">
        the Practice does not participate in <i>any</i> insurance
      </span>
      &nbsp;panels, and does not accept assignment from any insurance company.&nbsp;
      <span className="bold underline">
        Consequently, I am responsible for payment in full at time of service and charges are determined by the
        Practice.
      </span>
    </p>,
    <p>
      <span className="underline">No Responsibility To Determine Eligibility for Benefits:</span>
      &nbsp; the Pratice is not responsible for determining eligibility for benefits or for assisting me with collecting
      insurance benefits and has no responsibility to correspond with or telephone or email any insurer with which the
      Practice is an out-of-network provider.
    </p>,
    <p>
      <span className="underline">My Financial Responsibility:</span>
      &nbsp;I am financially responsible for any charges for services. I also agree to be responsible for costs and
      expenses, including court costs, attorney fees and interest, should it be necessary for the Practice to take
      action to secure payment of an outstanding balance.
    </p>,
    <p>
      <span className="underline">Charges:</span>
      &nbsp;In addition to the consultation fee, the Practice may recommend additional specific services such as blood
      or urine tests that may be additional costs.
    </p>,

    <p>
      <span className="underline">Physician-Patient Arbitration Agreement</span>
    </p>,

    <p>
      Article 1: Agreement to Arbitrate: It is understood that any dispute as to medical malpractice, that is as to
      whether any medical services rendered under this contract were unnecessary or unauthorized or were improperly,
      negligently or incompetently rendered, will be determined by submission to arbitration as provided by California
      law, and not by a lawsuit or resort to court process except as California law provides for judicial review of
      arbitration proceedings. Both parties to this contract, by entering into it, are giving up their constitutional
      right to have any such dispute decided in a court of law before a jury, and instead are accepting the use of
      arbitration.
    </p>,
    <p>
      Article 2: All Claims Must be Arbitrated: It is the intention of the parties that this agreement bind all parties
      whose claims may arise out of or relate to treatment or service provided by the physician including any spouse or
      heirs of the patient and any children, whether born or unborn, at the time of the occurrence giving rise to any
      claim. In the case of any pregnant mother, the term “patient” herein shall mean both the mother and the mother’s
      expected child or children. All claims for monetary damages exceeding the jurisdictional limit of the small claims
      court against the physician, and the physician’s partners, associates, association, corporation or partnership,
      and the employees, agents and estates of any of them, must be arbitrated including, without limitation, claims for
      loss of consortium, wrongful death, emotional distress or punitive damages. Filing of any action in any court by
      the physician to collect any fee from the patient shall not waive the right to compel arbitration of any
      malpractice claim.
    </p>,

    <p>
      Article 3: Procedures and Applicable Law: A demand for arbitration must be communicated in writing to all parties.
      Each party shall select an arbitrator (party arbitrator) within thirty days and a third arbitrator (neutral
      arbitrator) shall be selected by the arbitrators appointed by the parties within thirty days of a demand for a
      neutral arbitrator by either party. Each party to the arbitration shall pay such party’s pro rata share of the
      expenses and fees of the neutral arbitrator, together with other expenses of the arbitration incurred or approved
      by the neutral arbitrator, not including counsel fees or witness fees, or other expenses incurred by a party for
      such party’s own benefit. The parties agree that the arbitrators have the immunity of a judicial officer from
      civil liability when acting in the capacity of arbitrator under this contract. This immunity shall supplement, not
      supplant, any other applicable statutory or common law. Either party shall have the absolute right to arbitrate
      separately the issues of liability and damages upon written request to the neutral arbitrator.
    </p>,

    <p>
      The parties consent to the intervention and joinder in this arbitration of any person or entity which would
      otherwise be a proper additional party in a court action, and upon such intervention and joinder any existing
      court action against such additional person or entity shall be stayed pending arbitration. The parties agree that
      provisions of California law applicable to health care providers shall apply to disputes within this arbitration
      agreement.
    </p>,

    <p>
      Article 4: General Provisions: All claims based upon the same incident, transaction or related circumstances shall
      be arbitrated in one proceeding. A claim shall be waived and forever barred if (1) on the date notice thereof is
      received, the claim, if asserted in a civil action, would be barred by the applicable California statute of
      limitations, or (2) the claimant fails to pursue the arbitration claim in accordance with the procedures
      prescribed herein with reasonable diligence. With respect to any matter not herein expressly provided for, the
      arbitrators shall be governed by the California Code of Civil Procedure provisions relating to arbitration.
    </p>,

    <p>
      Article 5: Revocation: This agreement may be revoked by written notice delivered to the physician within 30 days
      of signature. It is the intent of this agreement to apply to all medical services rendered any time for any
      condition.
    </p>,

    <p>
      Article 6: Retroactive Effect: If patient intends this agreement to cover services rendered before the date it is
      signed (including, but not limited to, emergency treatment) patient should initial below:
    </p>,

    <p>
      If any provision of this arbitration agreement is held invalid or unenforceable, the remaining provisions shall
      remain in full force and shall not be affected by the invalidity of any other provision.
    </p>,

    <p>
      I understand that I have the right to receive a copy of this arbitration agreement. By my signature below, I
      acknowledge that I have received a copy.
    </p>,

    <p>
      NOTICE: BY SIGNING THIS AGREEMENT YOU ARE AGREEING TO HAVE ANY ISSUE OF MEDICAL MALPRACTICE DECIDED BY NEUTRAL
      ARBITRATION AND YOU ARE GIVING UP YOUR RIGHT TO A JURY OR COURT TRIAL. SEE ARTICLE 1 OF THIS AGREEMENT.
    </p>,

    <div className="gray-paragraph">
      <p>
        I have carefully read this entire form, including the Consents and the Policies, which is printed in English,
        and acknowledge that English is a language I read and understand, and that I understand the form. I do not feel
        rushed or impaired, nor am I under the influence of a sedative or sleep-inducing medication.
      </p>

      <p>
        I accept and agree to all of the terms above. I am free to refuse or withdraw my consent and to discontinue
        participation in any treatment, service, or research at any time without fear of reprisal against or prejudice
        to me. I may request and receive a copy of this form from the Practice. If any portion of this form is held
        invalid, the rest of the document will continue in full force and effect.
      </p>
    </div>,
  ],
}
