import React from 'react'
import Icon from './Icon'

const Tick = (props) => (
  <Icon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="square"
      strokeMiterlimit={10}
      d="M18 34l8 8 20-20"
      strokeWidth={4}
    />
  </Icon>
)

export default Tick
