import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useQueryParam, StringParam } from 'use-query-params';

import { NavBar } from '../../../../components/ui'
import { Back } from '../../../../components/general'
import { validateTokenAction } from '../../../../resources'
import { validateStripeConnectionOauth } from '../../../../utils/hooks/useStripe';

const handleOauthFlow = (location, history, state, code, setMessage, refreshCurrentUser) => () => {
  validateStripeConnectionOauth(state, code)
    .then(() => refreshCurrentUser(true))
    .then(() => history.push('/'))
    .catch(e => setMessage(e?.response?.data?.error))
}

const StripeConnectOath = ({ location, history }) => {
  const [state] = useQueryParam('state', StringParam);
  const [code] = useQueryParam('code', StringParam);

  const dispatch = useDispatch()
  const [message, setMessage] = useState()
  const refreshCurrentUser = hasToken => dispatch(validateTokenAction(hasToken))
  useEffect(handleOauthFlow(location, history, state, code, setMessage, refreshCurrentUser), [])

  return (
    <div className="View">
      <div className="section">
        <Back />
      </div>
      <NavBar />

      <div className="section">
        <div className="section-title">Stripe connect Oauth</div>

        <p>{message ? message : 'Loading...'}</p>
      </div>
    </div>
  )
}

export default StripeConnectOath
