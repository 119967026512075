import React, { useState } from 'react'
import classNames from 'classnames'
import QuestionMarkFilled from '../icons/QuestionMarkFilled'

const ToolTip = ({ text, tipText }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <div
      className={classNames('ToolTip')}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div className="tooltip-text-wrapper">
        <div className="tooltip-text">{text}</div>
        <QuestionMarkFilled />
      </div>
      <div className={classNames('tooltip-popup', { show: showTooltip })}>
        <p className="">{tipText}</p>
      </div>
    </div>
  )
}

export default ToolTip
