import React from 'react'
import { get, isEmpty } from 'lodash'
import { useAppointment } from '../../../utils/hooks/useAppointment'
import { NavBar, LoadingSpinner, Card } from '../../../components/ui'
import { AppointmentCard } from '../../../components/appointments'
import { ScheduleAppointmentPrompt } from '../../../components/dashboard'
import { Appointments, Results } from '../../../components/icons'

const PatientDashboard = () => {
  // Could rely purely on redux here and not do a call but using react query ensures data isn't stale
  // We could clean up the store and just store appointment ID
  const { status, data, error } = useAppointment()
  const appointment = get(data, 'data', null)

  if (status === 'loading') {
    return <LoadingSpinner />
  }

  if (status === 'error') {
    return <div>Error: {error.message}</div>
  }

  return (
    <div className="PatientDashboard View">
      <NavBar />
      <div className="section">
        <div className="section-title">Upcoming Appointment</div>
        {status === 'loading' ? (
          <LoadingSpinner />
        ) : !isEmpty(appointment) ? (
          <AppointmentCard appointment={appointment} />
        ) : (
          <ScheduleAppointmentPrompt />
        )}
      </div>
      <div className="section stretch">
        <div className="section-title">More</div>
        <Card to="/appointments">
          <Appointments />
          <div>My Appointments</div>
        </Card>
        <Card to="/results">
          <Results />
          <div>My Results</div>
        </Card>
      </div>
      <div className="section">
        <div className="message text-center">
          Need Help? {''}
          <a href="mailto:team@streammd.com" className="link medium">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  )
}

export default PatientDashboard
