import React from 'react'
import { Logo } from '../../components/general'
import ResetForm from '../../components/forms/ResetForm'

const Reset = () => {
  return (
    <div className="View Login">
      <div className="section">
        <Logo />
      </div>
      <ResetForm />
    </div>
  )
}

export default Reset
