import React from 'react'

const ToggleAll = ({ toggle, items, numOn, onText, offText }) => {
  let text = onText,
    onClick = () => toggle(items)

  if (numOn >= items.length) {
    text = offText
    onClick = () => toggle([])
  }

  return (
    <div className="ToggleAll" onClick={onClick}>
      {text}
    </div>
  )
}
export default ToggleAll
