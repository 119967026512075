import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { FieldsWrapper, Field, Fields } from '../fields'
import { useQuestion } from '../../utils/hooks/useQuestion'

const IntakeFields = ({ questions, readOnly, allowModals, isDoctor, values }) => {
  const { gender } = useSelector((state) => state.auth.me)
  const { isRequiredQuestion } = useQuestion()

  return questions
    .filter(({ filter }) => !filter || !filter({ ...values, gender }))
    .map((question, index) => {
      return (
        <div key={index} className="inner-form-section">
          <div className="section-title">
            {!isDoctor && question.question}
            {isRequiredQuestion(question) && <span className="required">*</span>}
          </div>
          {question.answers.map((field, index) => {
            return (
              <FieldsWrapper
                key={index}
                className={classNames({ 'read-only': readOnly })}
                condition={allowModals && field.modal}
                name={question.name}
                {...field}
              >
                {field.fieldGroups ? (
                  <div className="field-groups">
                    {field.fieldGroups.map((fieldGroup) => (
                      <Fields
                        key={fieldGroup.name}
                        readOnly={readOnly}
                        isDoctor={isDoctor}
                        {...fieldGroup}
                        name={`${field.name}.${fieldGroup.name}`}
                      />
                    ))}
                  </div>
                ) : field.fields ? (
                  <Fields key={field.name} isDoctor={isDoctor} readOnly={readOnly} {...field} />
                ) : (
                  <Field key={field.name} isDoctor={isDoctor} readOnly={readOnly} {...field} />
                )}
              </FieldsWrapper>
            )
          })}
        </div>
      )
    })
}

export default IntakeFields

IntakeFields.defaultProps = {
  allowModals: true,
  readOnly: false,
  isDoctor: false,
}
