import React from 'react'
import Icon from './Icon'

const Users = (props) => (
  <Icon viewBox="0 0 32 32" className="fill-none" {...props}>
    <circle cx={26} cy={12} r={4} className="stroke tertiary" strokeWidth={2} strokeLinejoin="round" />
    <path
      d="M19 20c1.596-2.429 4.138-4 7-4 1.97 0 3.788.744 5.25 2"
      className="stroke tertiary"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <circle cx={6} cy={12} r={4} stroke="#23C48C" strokeWidth={2} strokeLinejoin="round" />
    <path
      d="M13 20c-1.597-2.429-4.138-4-7-4-1.97 0-3.788.744-5.25 2"
      className="stroke secondary"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <circle cx={16} cy={14} r={5} className="stroke primary" strokeWidth={2} strokeLinejoin="round" />
    <path
      d="M8 24a9.984 9.984 0 0 1 8-4 9.984 9.984 0 0 1 8 4"
      className="stroke primary"
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </Icon>
)

export default Users
