import React from 'react'
import Icon from './Icon'

const Congestion = (props) => (
  <Icon viewBox="0 0 32 32" className="stroke fill-none" {...props}>
    <path
      d="M28 28.667C28 29.955 26.88 31 25.5 31S23 29.955 23 28.667C23 26.333 25.5 24 25.5 24s2.5 2.333 2.5 4.667z"
      className="stroke secondary"
      strokeWidth={2}
    />
    <path
      d="M8 19a7 7 0 0 1-7-7M24 19a7 7 0 0 0 7-7M25 14a9 9 0 1 1-18 0M23 1l1 8M9 1L8 9"
      className="stroke primary"
      strokeWidth={2}
    />
  </Icon>
)

export default Congestion
