import React from 'react'
import Icon from './Icon'

const Appointments = (props) => (
  <Icon viewBox="0 0 32 32" className="fill-none" {...props}>
    <path
      d="M11 18a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM16 18a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM21 18a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM11 23a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM16 23a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM21 23a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
      className="fill secondary"
    />
    <path d="M2 10h21M9 6V0M29 12v17H3V3h20" className="stroke primary" strokeWidth={2} />
    <path d="M23 1h8v12l-4-1.905L23 13V1z" className="stroke tertiary" strokeWidth={2} />
  </Icon>
)

export default Appointments
