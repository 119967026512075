import React from 'react'
import Icon from './Icon'

const Notes = props => (
  <Icon viewBox="0 0 31 22" className="fill" {...props}>
    <path d="M0 21.432h20.667v-3.539H0v3.539zM0 .199v3.539h31V.199H0zm0 12.386h31V9.046H0v3.539z" />
  </Icon>
)

export default Notes
