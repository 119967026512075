import React from 'react'
import { NavBar, Card } from '../../../components/ui'
import { Results } from '../../../components/icons'

const AdminDashboard = () => {
  return (
    <div className="PatientDashboard View">
      <NavBar />
      <div className="section">
        <Card to="/admin/results/review">
          <Results />
          <div>Review Results</div>
        </Card>
        <Card to="/admin/order/shipments">
          <Results />
          <div>Test Order Shipments</div>
        </Card>
      </div>
    </div>
  )
}

export default AdminDashboard
