import React from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import { AppointmentList } from '../../../components/appointments'
import { Back } from '../../../components/general'
import { usePatient } from '../../../utils/hooks/usePatient'
import { LoadingSpinner } from '../../../components/ui'

const PatientAppointments = () => {
  let { id } = useParams()
  const { status, data, error } = usePatient(id)

  if (status === 'loading') {
    return <LoadingSpinner />
  }

  if (status === 'error') {
    return <div>Error: {error.message}</div>
  }

  const patient = get(data, 'data')

  return (
    <div className="View PatientAppointments">
      <div className="section header">
        <Back />
      </div>
      <div className="section">
        <div className="section-title">{patient.firstName}'s Appointments</div>
        <AppointmentList patientId={id} />
      </div>
    </div>
  )
}

export default PatientAppointments
